import React, { useEffect, useImperativeHandle, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Cancel from "@mui/icons-material/Cancel";
import Visibility from "@mui/icons-material/Visibility";
import { blobToFile, getUpdateImageFormat } from 'utils/global';

import { FormErrorMessage } from "./FormErrorMessage";
import { colorList } from "assets/styles/mui";
import { images } from "utils/constants";
// import useWatermarkAddedToTheImage from "hooks/useWatermarkAddedToTheImage";
import useResponsive from "hooks/useResponsive";
import imageCompression from "browser-image-compression";
import { CircularProgressProps, LinearProgressProps, Box, CircularProgress, LinearProgress, Typography, Grid } from "@mui/material";

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return props?.value ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" color="success" sx={{ padding: 1 }} {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="success">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    ) : null;
}

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return props?.value ? (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    ) : null;
}


interface Props {
    error?: any;
    onClear?: () => void;
    onViewImage?: (file: any) => void;
    defaultFileName?: string | undefined;
    defaultFileUrl?: string;
    label?: string;
    formLabel?: string;
    fileData?: any;
    isPreviewImage?: boolean;
    wasTriggered?: boolean;
    reSized?:boolean;
    onChange?: (file: any) => void;
}

export const CustomFileUpload = React.forwardRef<
    HTMLInputElement | any,
    Props & React.HTMLProps<HTMLInputElement>
>((props, ref) => {
    const {
        error,
        label,
        onClear,
        defaultFileName,
        fileData,
        isPreviewImage,
        onViewImage,
        accept,
        defaultFileUrl,
        formLabel,
        wasTriggered,
        reSized,
        onChange,
        ...rest
    } = props;
    const mdUp = useResponsive("up", "md");
    const [progress, setProgress] = useState(0)
    // File processing
    // const [fileProcessingFn, processedFile, isProcessing] = useWatermarkAddedToTheImage();

    const [fileName, setFileName] = useState<string | undefined>(
        defaultFileName ?? undefined
    );
    const [file, setFile] = useState<any>(null);
    const [filePreview, setFilePreview] = useState<any>(null);

    const handleClear = () => {
        setFileName(undefined);
        setFile(null);
        setProgress(0);
        setFilePreview(null);
        if (onClear) {
            onClear();
        }
    }

    async function handleImageUpload(imageFile: any) {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            onProgress: (n: number) => setProgress(n)
        }
        try {
            if (imageFile.size / 1024 / 1024 >= 1) {
                const compressedFile = await imageCompression(imageFile, options);
                const file = blobToFile(compressedFile, compressedFile.name);
                setFile(file);
                setFileName(file?.name);
                if (onChange) {
                    onChange([file]);
                }
            } else {
                setFile(imageFile);
                setFileName(imageFile?.name);
                if (onChange) {
                    onChange([imageFile]);
                }
            }
        } catch (handleImageUploadError) {
            console.log({handleImageUploadError});
        }
    }

    function fileRender() {
        try {
            let objectUrl = "";
            let previewImage = "";
            if (file) {
                const split = file?.name?.split(".");
                if (["pdf", "xls", "xlsx", "docx"].includes(split?.pop())) {
                    objectUrl = URL.createObjectURL(file);
                    previewImage = images.document;
                } else {
                    objectUrl = URL.createObjectURL(file);
                    previewImage = URL.createObjectURL(file);
                }
            } else if (defaultFileUrl) {
                objectUrl = defaultFileUrl;
            }

            return { objectUrl, previewImage };
        } catch (fileRenderError) {
            console.log({ fileRenderError })
        }

    }

    function onViewFile() {
        const fileData: any = fileRender();
        window.open(fileData.objectUrl, "_blank");
    }

    useEffect(() => {
        if (file) {
            const fileData = fileRender();
            setFilePreview(fileData?.previewImage)
        } else {
            setFilePreview(null)
        }
    }, [file])

    useEffect(() => {
        if (wasTriggered) {
            handleClear()
        }
    }, [wasTriggered])

    useEffect(() => {
        if (defaultFileUrl) {
            const fileData = getUpdateImageFormat(defaultFileUrl);
            setFilePreview(fileData.previewImage);
            setFileName(fileData.fileName)
        }
    }, [defaultFileUrl])


    // useEffect(() => {
    //     if (!!processedFile && !isProcessing) {
    //         setFile([processedFile.textWatermarkFile]);
    //         setFileName(processedFile.fileName);
    //         if (onChange) {
    //             onChange([processedFile.textWatermarkFile]);
    //         }
    //     }
    // }, [processedFile, isProcessing]);

    return (
        <FormControl fullWidth>
            {formLabel && <div style={{ marginBottom: 8 }}>{formLabel}</div>}
            <Grid container spacing={1}>
                <Grid item xs={progress > 0 ? 10 :12}>
                    <Button
                        fullWidth
                        component="label"
                        variant="outlined"
                        disableElevation
                        disableFocusRipple
                        startIcon={
                            fileName ? (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <IconButton
                                        size="small"
                                        style={{ margin: 0, marginRight: 7 }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onViewFile();
                                            if (onViewImage) {
                                                onViewImage(file);
                                            }
                                        }}
                                    >
                                        <Visibility color="secondary" />
                                    </IconButton>
                                    <label style={{ opacity: 0.6 }}>
                                        {fileName
                                            ? fileName
                                                ?.substring(0, mdUp ? 25 : 10)
                                                ?.replace(/[\s\n]+$/, "")
                                                ?.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/, "") + "..."
                                            : label}
                                    </label>
                                </div>
                            ) : (
                                <label style={{ opacity: 0.6 }}>{label}</label>
                            )
                        }
                        endIcon={
                            fileName ? (
                                <IconButton
                                    type="button"
                                    size="small"
                                    onClick={handleClear}
                                >
                                    <Cancel color="error" />
                                </IconButton>
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        color: colorList.secondaryDark,
                                        backgroundColor: colorList.secondaryLight,
                                        padding: 10,
                                        marginRight: -15,
                                    }}
                                >
                                    <span style={{ fontSize: 12, marginRight: 5 }}>Browse</span>
                                </div>
                            )
                        }
                        sx={{
                            justifyContent: "space-between",
                            height: 40,
                            textTransform: "none",
                            fontSize: 12,
                            borderRadius: 1,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                        }}
                    >
                        <input
                            type="file"
                            hidden
                            accept={accept}
                            ref={ref}
                            {...rest}
                            onChange={async (e) => {
                                if (reSized && e.target?.files) {
                                    await handleImageUpload(e.target.files?.[0]);
                                }else{
                                    setFile(e.target.files?.[0]);
                                    setFileName(e.target.files?.[0]?.name)
                                    if (onChange) {
                                        onChange(e);
                                    }
                                }
                            }}
                        />
                    </Button>
                </Grid>
                {progress > 0 &&
                <Grid item xs={2}>
                    <CircularProgressWithLabel value={progress} />
                </Grid>
                }
            </Grid>
            {!error && (
                <span style={{ fontSize: 12, marginTop: 5, color: "rgba(0,0,0,0.5)" }}>
                    ({accept} supported only)
                </span>
            )}
            {isPreviewImage && filePreview && (
                <img
                    src={filePreview}
                    style={{ marginTop: 20, height: 150, width: 150 , objectFit:'cover'}}
                />
            )}
            {/* error message */}
            <FormErrorMessage error={error} />
        </FormControl>
    );
});

CustomFileUpload.defaultProps = {
    accept: ".png, .jpg, .jpeg",
    label: "Choose file",
    reSized:true,
    isPreviewImage: true,
};

export default CustomFileUpload;
