import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// material
import { Box, Grid, Stack, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { HoverLoader } from "components";
import { useCreateLookupOptions, useToastMessage } from "hooks";
import { masterLookupServices } from "utils/constants";
import { services } from "redux/services";
import { RootReducerState } from "utils/types";
import { getMasterLookupData } from "redux/actions";
import GeneralForm from "./GeneralForm";
import AmenitiesForm from "./AmenitiesForm";
import FileUploadForm from "./FileUploadForm";

const Form: React.FC = () => {
  const { uuid, tab }: any = useParams<any>();
  const { toastMessage } = useToastMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [defaultData, setDefaultData] = useState<any>(null);
  const [files, setFiles] = React.useState([]);

  const { propertyLookUp } = useSelector(
    ({ masterLookupReducer }: RootReducerState) => {
      return {
        propertyLookUp:
          masterLookupReducer[masterLookupServices.getPropertyLookup].data,
      };
    },
    shallowEqual
  );

  const selectOptions = useCreateLookupOptions(propertyLookUp);

  useEffect(() => {
    dispatch(getMasterLookupData(masterLookupServices.getPropertyLookup));
  }, []);

  //RESET DEFAULTVALUE IN UPDATE MODE
  useEffect(() => {
    if (uuid) {
      setLoading(true);
      services
        .getPropertyByUuid(uuid)
        .then((res: any) => {
          setLoading(false);
          const response = res.result;
          setDefaultData(response);
          setFiles(response.files);
        })
        .catch((err: any) => {
          setLoading(false);
          toastMessage(err?.data?.message || 'Something Wrong', "error");
        });
    }
  }, [uuid]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // setTab(newValue);
    navigate(`/admin/properties/update/${newValue}/${uuid}`);
  };

  return (
    <TabContext value={tab}>
      {loading && <HoverLoader />}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              mb={2}
              ml={3}
            >
              <ArrowBackIcon
                color="primary"
                onClick={() => navigate('/admin/properties')}
                style={{
                  border: "1px solid",
                  borderRadius: 16,
                  marginRight: 16,
                  cursor: "pointer",
                }}
              />
              <Typography variant="h5" color="primary">
                {" "}
                {formatMessage({ id: "update" }) +
                  " " +
                  formatMessage({ id: "property" })}
              </Typography>
            </Stack>
          </Grid>
          <Grid item lg={8} md={6} sm={12} xs={12}>
            <TabList onChange={handleChange} >
              <Tab label="General" value="general" />
              <Tab label="Amenities" value="amenities" />
              <Tab label="Gallery" value="files" />
            </TabList>
          </Grid>
        </Grid>
      </Box>
      <TabPanel value="general" sx={{ paddingY: 0 }}>
        {defaultData && (
          <GeneralForm
            uuid={uuid}
            formType="update"
            defaultData={defaultData}
            selectOptions={selectOptions}
          />
        )}
      </TabPanel>
      <TabPanel value="amenities" sx={{ paddingY: 0 }}>
        <AmenitiesForm
          uuid={uuid}
          selectOptions={propertyLookUp?.amenities}
          defaultData={defaultData}
        />
      </TabPanel>
      <TabPanel value="files" sx={{ paddingY: 0 }}>
        <FileUploadForm uuid={uuid} defaultData={defaultData} />
      </TabPanel>
    </TabContext>
  );
};

export default Form;
