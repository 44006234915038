
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { TableEditButton, DeleteButton, TableButtonGroup } from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { adminColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useCheckRole, useToastMessage } from 'hooks';
import { roleData } from 'utils/constants/role';

const User: React.FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const { pathname } = useLocation();
    const { toastMessage } = useToastMessage();
    const { isSuperAdmin, isAdmin } = useCheckRole();
    const dispatch = useDispatch();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { tableData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            tableData: masterPaginationReducer[masterPaginationServices.admin].data,
            loading: masterPaginationReducer[masterPaginationServices.admin].loading,
        }),
        shallowEqual
    );

    const { items, meta: { totalItems } } = tableData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        dispatch(getMasterPaginationData(masterPaginationServices.admin, params));
    }

    const columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const roles = items[tableMeta.rowIndex]?.roles.map((item:any) => item.code) ?? [];
                if(isSuperAdmin){
                    return !roles?.includes(roleData.superadmin) && (
                        <TableButtonGroup>
                            <TableEditButton
                                tooltipLabel="Edit"
                                onClick={() => handleRowClick(items[tableMeta.rowIndex])}
                            />
                            <DeleteButton
                                onDelete={() => onDeleteData(items[tableMeta.rowIndex])}
                            />
                        </TableButtonGroup>
                    )
                }else if (isAdmin){
                    return !roles?.includes(roleData.admin) && (
                        <TableButtonGroup>
                            <TableEditButton
                                tooltipLabel="Edit"
                                onClick={() => handleRowClick(items[tableMeta.rowIndex])}
                            />
                            <DeleteButton
                                onDelete={() => onDeleteData(items[tableMeta.rowIndex])}
                            />
                        </TableButtonGroup>
                    )
                }
            }
        }
    }

    function handleRowClick(rowData: any) {
        navigate(`${pathname}/update/${rowData?.uuid}`, { ...rowData });
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteAdmin(data.uuid)
            .then((res: any) => {
                setDeleteLoading(false);
                onApiCall();
                toastMessage(formatMessage({ id: "delete-message" }));
            })
            .catch((err) => {
                setDeleteLoading(false);
                toastMessage(err?.data?.message, 'error');
            })
    }

    return (
        <PageLayout title={formatMessage({ id: "admin" })} >
            <CustomTable
                columns={[...adminColumns(formatMessage), columnAction]}
                tableData={items}
                tableState={tableState}
                rowsCount={totalItems}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />
        </PageLayout>
    )
}

export default User;
