import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate } from 'utils/constants';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: (data:any) => void;
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, reset } = useForm<any>({ mode: 'all', defaultValues: { isActive: true } });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState('')

    function onSubmit(data: any) {
        setLoading(true);
        data.photo = data?.image?.[0];
        delete data.image;
        const apiFunc = formMode === 'create' ? 'createOwner' : 'updateOwner';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "owner" }));
                closeModal();
                refreshData(res.result);
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                uuid: selectedData.uuid,
                fullName: selectedData.fullName,
                phone: selectedData.phone,
                email: selectedData.email,
                address: selectedData.address,
                isActive: selectedData.isActive,
                image: null,
            })
            setFileImage(selectedData.photo)
        }
    }, [formMode === 'update']);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "full-name" })}
                        name="fullName"
                        control={control}
                        error={errors?.fullName}
                        rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "email" })}
                        name="email"
                        control={control}
                        error={errors?.email}
                        rules={validationRule.textbox({ type: "email" })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "address" })}
                        name="address"
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "phone" })}
                        name="phone"
                        control={control}
                        error={errors?.phone}
                        rules={validationRule.textbox({ type: "contact" })}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        {...register('image', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                        })}
                        label={formatMessage({ id: "image" })}
                        error={errors?.image}
                        defaultFileUrl={fileImage}
                        defaultFileName={''}
                        onClear={() => {
                            setValue(`image`, null);
                            clearErrors(`image`);
                        }}
                        onChange={(file:any) => setValue('image' , file)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="isActive"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;
