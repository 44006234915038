import {
    AUTH_START,
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_RESET,
    RESET_TOKEN,
} from "../actionTypes";
import { AnyAction, Dispatch } from 'redux';
import Axios from 'axios';
import { API_ENDPOINTS } from "redux/apiEndPoints";

export const login = (payload: any, toastMessage: any,navigate :any): any => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({ type: AUTH_START });
        Axios.post(`${API_ENDPOINTS.LOGIN}`, payload)
            .then((res: any) => {
                const { data, status } = res;
                    dispatch({ type: AUTH_SUCCESS, payload: data?.result });
                    // dispatch(getAccount(data?.result?.uuid,data?.result?.accessToken, toastMessage));
            })
            .catch((err) => {
                if(err?.response?.data?.url) {
                    navigate(err?.response?.data?.url);
                }
                dispatch({ type: AUTH_FAIL });
                const message = JSON.stringify(err?.response?.data?.error) ?? 'Opps something wrong!';
                    toastMessage(message,'error');
            });
    };
};

export const getAccount = (uuid: any, token :any ,toastMessage: any): any => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({ type: AUTH_START });
        Axios.get(`${API_ENDPOINTS.GET_ACCOUNT}?uuid=${uuid}`, { headers: {"Authorization" : `Bearer ${token}`}})
            .then((res: any) => {
                const { data, status } = res;
                if (status === 200) {
                    dispatch(selectAccount({ account_uuid : data?.result?.accounts?.[0]?.uuid, user_uuid : data?.result?.uuid ,},token,toastMessage));
                } else {
                    toastMessage("Something went wrong. Please try again", 'error');
                    dispatch({ type: AUTH_FAIL });
                }
            })
            .catch((err) => {
                dispatch({ type: AUTH_FAIL });
                toastMessage(err?.response?.data.message,'error');
            });
    };
};


export const selectAccount = (payload: any, token:any, toastMessage: any): any => {
    return (dispatch: Dispatch<AnyAction>) => {
        dispatch({ type: AUTH_START });
        Axios.post(`${API_ENDPOINTS.SELECT_ACCOUNT}`, payload,{ headers: {"Authorization" : `Bearer ${token}`}})
            .then((res: any) => {
                const { data, status } = res;
                if (status === 200) {
                    dispatch({ type: AUTH_SUCCESS, payload: data?.result });
                } else {
                    toastMessage("Something went wrong. Please try again", 'error');
                    dispatch({ type: AUTH_FAIL });
                }
            })
            .catch((err) => {
                dispatch({ type: AUTH_FAIL });
                toastMessage(err?.response?.data.message,'error');
            });
    };
};

export function authReset() {
    return {
        type: AUTH_RESET
    }
}

export function resetToken(payload: any) {
    return {
        type: RESET_TOKEN,
        payload
    }
}