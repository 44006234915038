import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomTextArea, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import { FileAcceptData, fileValidate, images } from 'utils/constants';
import { services } from 'redux/services';
import CustomTextEditor from 'components/forms/CustomTextEditor';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const navigate = useNavigate();

    const [updateId, setUpdateId] = useState<number>(0);
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const [loading, setLoading] = useState(false);
    const [fileData, setFileData] = useState({
        objectUrl: "",
        previewImage: "",
    });

    function onSubmit(data: any) {
        setLoading(true);
        data.file = data?.file?.[0];
        if (formType === 'update') {
            data.id = updateId;
            data.uuid = uuid;
        }
        const apiFunc = formType === 'create' ? 'createBanner' : 'updateBanner';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'banner' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            }).finally(() => setLoading(false))
    }

    useEffect(() => {
        let objectUrl = "";
        let previewImage = "";
        if (watch('file')?.[0]) {
            const split = watch('file')?.[0]?.name?.split('.');
            if (['pdf', 'xls', 'xlsx', 'docx'].includes(split?.pop())) {
                objectUrl = URL.createObjectURL(watch('file')?.[0]);
                previewImage = images.document;
            } else {
                objectUrl = URL.createObjectURL(watch('file')?.[0]);
                previewImage = URL.createObjectURL(watch('file')?.[0]);
            }
        }
        setFileData({ objectUrl, previewImage })
    }, [watch('file')?.[0]])


    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getBannerByUuid(uuid)
                .then((res: any) => {
                    const response = res.result;
                    setUpdateId(+response.id);
                    reset({
                        title: response.title,
                        subTitle: response.subTitle,
                        description: response.description,
                        isActive: response.isActive,
                        file: null
                    })
                    let previewImage;
                    let objectUrl = response.files?.[0]?.url;
                    const split = objectUrl?.split('.');
                    if (['pdf', 'xls', 'xlsx', 'docx'].includes(split?.pop())) {
                        previewImage = images.document;
                    } else {
                        previewImage = objectUrl;
                    }
                    setFileData({ objectUrl, previewImage })
                })
                .catch((err: any) => {
                    toastMessage(err?.data?.message || 'Something Wrong', 'error');
                }).finally(() => setLoading(false))
        }

    }, [formType === 'update', uuid]);

    return (
        <FormLayout formType={formType} title={formatMessage({ id: "banner" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "title" })}
                        name="title"
                        control={control}
                        error={errors?.title}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "sub-title" })}
                        name="subTitle"
                        control={control}
                        error={errors?.subTitle}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomTextEditor
                        name="description"
                        defaultValue={watch('description')}
                        error={errors?.description}
                        onChange={(editor:any) => setValue('description', editor)}
                    />
                </Grid>
                <Grid item xs={12} >
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        {...register('file', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                        })}
                        label={formatMessage({ id: "image" })}
                        error={errors?.file}
                        fileData={fileData}
                        defaultFileName={''}
                        onClear={() => {
                            setValue(`file`, null);
                            clearErrors(`file`);
                        }}
                        onChange={(file:any) => setValue('file' , file)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="isActive"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                    />
                </Grid>
            </Grid>
        </FormLayout>
    )
}

export default Form;