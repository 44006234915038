import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Button, Grid, Stack } from '@mui/material';
import { Save } from '@mui/icons-material';

import { CustomCheckBox, CustomFileUpload, CustomTextArea, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate, images } from 'utils/constants';

const Account: React.FC<any> = ({ defaultData }: any) => {
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' }) as any;
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [logoData, setLogoData] = useState();

    function onSubmit(data: any) {
        setLoading(true);
        data.uuid = defaultData?.account?.uuid;
        data.logo = data?.logo?.[0];
        const apiFunc = 'updateAccount';
        const message = 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'account' }) }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            }).finally(() => setLoading(false))
    }


    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        // setLoading(true);
        if (defaultData) {
            reset({
                name: defaultData.account.name,
                phone: defaultData.account.phone,
                address: defaultData.account.address,
                email: defaultData.account.email,
                detail: defaultData.account.detail,
                isActive: defaultData.account.isActive,

            });
            setLogoData(defaultData.account.logo);
        }
    }, [defaultData]);

    return (
        <SectionLayout title={formatMessage({ id: "agent-information" })}>
            {loading && <HoverLoader />}
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "email" })}
                        name="email"
                        disabled
                        control={control}
                        error={errors?.account?.email}
                        rules={validationRule.textbox({ type: "email", required: true })}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "name" })}
                        name="name"
                        control={control}
                        error={errors?.account?.name}
                        rules={validationRule.textbox({ required: true})}
                    />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "phone" })}
                        name="phone"
                        control={control}
                        error={errors?.account?.phone}
                        rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                    />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "address" })}
                        name="address"
                        control={control}
                        error={errors?.account?.address}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "detail" })}
                        name="detail"
                        control={control}
                        minRows={6}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="isActive"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        {...register('logo', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                        })}
                        label="Logo"
                        defaultFileUrl={logoData}
                        defaultFileName={''}
                        onClear={() => {
                            setValue(`logo`, null);
                            clearErrors(`logo`);
                        }}
                        onChange={(file:any) => setValue('logo' , file)}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" my={5}>
                <Button variant="contained" type='submit' onClick={handleSubmit(onSubmit)} startIcon={<Save />}>
                    Update Account
                </Button>
            </Stack>
        </SectionLayout>
    )
}

export default Account;