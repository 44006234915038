import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import PersonAdd from '@mui/icons-material/PersonAdd';
import LinkOff from '@mui/icons-material/LinkOff';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';

import { ReactComponent as UserEditIcon } from 'assets/images/icons/user-edit.svg';
import { ReactComponent as CheckListIcon } from 'assets/images/icons/checklist-icon.svg';
import { ReactComponent as ReSamplingIcon } from 'assets/images/icons/resampling-icon.svg';
import { ReactComponent as ResultIcon } from 'assets/images/icons/result-icon.svg';
import { ReactComponent as DispatchIcon } from 'assets/images/icons/dispatch-icon.svg';

import { images } from 'utils/constants';
import { colorList } from 'assets/styles/mui';
import { Flag } from '@mui/icons-material';
import ApprovalIcon from '@mui/icons-material/Approval';

interface Props extends IconButtonProps {
    tooltipLabel?: string;
    imageStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}

const iconStyle = {
    padding: 3,
    borderRadius: 25,
    borderStyle: 'solid',
    borderColor: 'inherit',
}

const TableViewButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FEF9DB',
        color: '#D99800'
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <Visibility htmlColor="inherit" />
            </IconButton>
        </Tooltip >
    )
});

const TableEditButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: colorList.secondaryLight,
        color: colorList.secondary
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? "Edit"}>
            <IconButton
                size="small"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <EditIcon htmlColor="inherit" />
                {/* <img src={images.editIcon} alt="edit icon" style={{ width: "25px", height: "25px" }} /> */}
            </IconButton>
        </Tooltip>
    )
});

const CustomTableButton = React.memo((props: Props & { children: JSX.Element }) => {
    const { tooltipLabel, children, style = {
        background: colorList.primaryLight,
        color: colorList.primaryDark
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                {children}
            </IconButton>
        </Tooltip>
    )
});

const TableStatusButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {}, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                {...rest}
            >
                <ApprovalIcon htmlColor="inherit"/>
                {/* <img src={images.refundIcon} alt="refund icon" style={{ width: "25px", height: "25px" }} /> */}
            </IconButton>
        </Tooltip>
    )
});

const TableDeleteButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FCDADA',
        color: "#FF0606"
    }, imageStyle, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="secondary"
                {...rest}
                style={{ ...iconStyle, ...style }}
            >
                <DeleteIcon htmlColor="inherit" />
            </IconButton>
        </Tooltip>
    )
});

const TableFlagButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#F8F8F8',
        color: "#D266D2"
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <Flag color="action" htmlColor="inherit" />
            </IconButton>
        </Tooltip>
    )
});

const TablePersonEditButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: colorList.secondaryLight,
        color: colorList.secondary
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <UserEditIcon style={{ height: "25px", width: "25px", objectFit: "cover" }} fill={colorList.secondary} />
            </IconButton>
        </Tooltip>
    )
});

const TableDeLinkButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FCDADA',
        color: "#C20000"
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <LinkOff />
            </IconButton>
        </Tooltip>
    )
});

const TableDischargeButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FCDADA',
        color: "#C20000"
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <PersonAddDisabledIcon htmlColor="inherit" />
                {/* <img src={images.dischargeIcon} alt="discharge icon" style={{ fill: "#C20000", width: 25, height: 25 }} /> */}
            </IconButton>
        </Tooltip>
    )
});

const TableReSamplingButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {}, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <ReSamplingIcon style={{ height: "25px", width: "25px", objectFit: "cover" }} fill="rgba(0, 0, 0, 0.54)" />
            </IconButton>
        </Tooltip>
    )
});

const TableResultEntryButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {}, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <ResultIcon style={{ height: "25px", width: "25px", objectFit: "cover" }} fill="rgba(0, 0, 0, 0.54)" />
            </IconButton>
        </Tooltip>
    )
});

const TableAuthorizationButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {}, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <CheckListIcon style={{ height: "25px", width: "25px", objectFit: "cover" }} fill="rgba(0, 0, 0, 0.54)" />
            </IconButton>
        </Tooltip>
    )
});

const TableDispatchButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {}, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <DispatchIcon style={{ height: "25px", width: "25px", objectFit: "cover" }} fill="rgba(0, 0, 0, 0.54)" />
            </IconButton>
        </Tooltip>
    )
});

const TablePermissionButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {
        background: '#FEF9DB',
        color: "#F6AC00"
    }, ...rest } = props;
    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <LockIcon htmlColor='inherit' />
                {/* <img src={images.lockIcon} alt="role and permission icon" style={{ height: "25px", width: "25px", objectFit: "cover" }} /> */}
            </IconButton>
        </Tooltip>
    )
});



export {
    TableViewButton,
    TableDeLinkButton,
    TableEditButton,
    TableDeleteButton,
    TableFlagButton,
    TablePersonEditButton,
    TableStatusButton,
    CustomTableButton,
    TableDischargeButton,
    TableReSamplingButton,
    TableResultEntryButton,
    TableAuthorizationButton,
    TableDispatchButton,
    TablePermissionButton
};