import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import { Container, Stack, InputAdornment, Typography, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Person from '@mui/icons-material/Person';

import { CustomTextBox } from 'components/forms';
import { useToastMessage } from 'hooks';
import { API_ENDPOINTS } from 'redux/apiEndPoints';
import { OtpConstant } from 'utils/constants';
import { HoverLoader } from 'components';
import { Helmet } from 'react-helmet-async';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0)
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 550,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.shadows,
  backgroundColor: theme.palette.background.default,
}));

export default function ForgetPassword() {
  const navigate = useNavigate();
  const { toastMessage } = useToastMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const { handleSubmit, formState: { errors }, control } = useForm({ mode: 'all' });

  async function onSubmit(data: any) {
    setLoading(true);
    Axios.post(`${API_ENDPOINTS.SEND_OTP}`, {
      email: data.email,
      otpType: OtpConstant.forgotPassword
    })
      .then((res: any) => {
        setLoading(false);
        toastMessage('Otp has sent to your mail', 'success')
        navigate(`/auth/verify?email=${res?.data?.result.otpFor}&otpType=${OtpConstant.forgotPassword}`);
      })
      .catch((err) => {
        setLoading(false);
        const message = JSON.stringify(err?.response?.data?.error) ?? 'Opps something wrong!';
        toastMessage(message, 'error');
        if (err?.response?.status === 406) {
          navigate(`/auth/verify?email=${data.email}&otpType=${OtpConstant.forgotPassword}`);
        }
      });
  }

  return (
    <Container maxWidth="xl" sx={{ background: "#F5F5F5" }}>
      <Helmet>
        <title>Forgot Password | Gharawas - Reset Your Account Password</title>
        <meta name="description" content="Forgot your password? Enter your email address to reset your password on Gharawas." />

        <meta name="keywords" content="Gharawas, agent forgot password, password reset, real estate" />

        <meta name="robots" content="index, follow" />

        <meta name="author" content="Gharawas" />

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {loading && <HoverLoader />}
      <ContentStyle>
        <Card sx={{ p: 2 }}>
          <Typography variant="h4" gutterBottom>
            Forget Password
          </Typography>
          <Typography variant="body2" sx={{ mb: 5 }}>
            Already have an account? {' '}
            <Link to='/auth/login' style={{ textDecoration: 'none', color: "#6327e4", fontSize: 14 }}>
              Sign In
            </Link>
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <CustomTextBox
                label="Email"
                error={errors.email}
                name="email"
                rules={{ required: true }}
                control={control}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" >
                      <span className="inpt_back"><Person color="primary" /></span>
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }}>
              Send OTP
            </LoadingButton>
          </form>
        </Card>
      </ContentStyle>
    </Container>
  );
}
