import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { slugify, validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { FileAcceptData, adPlacementList, fileValidate } from 'utils/constants';

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, reset } = useForm<any>({ mode: 'all', defaultValues: { isActive: true } });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [fileImage, setFileImage] = useState('')

    function onSubmit(data: any) {
        setLoading(true);
        data.pageName = slugify(data.pageName);
        data.placement =  data?.placement?.value;
        data.image = data?.image?.[0];
        const apiFunc = formMode === 'create' ? 'createAdvertisement' : 'updateAdvertisement';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "advertisement" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                uuid: selectedData.uuid,
                title: selectedData.title,
                placement: adPlacementList?.find(item =>item.value == selectedData.placement),
                pageName: selectedData.pageName,
                sequence: selectedData.sequence,
                detail: selectedData.detail,
                isActive: selectedData.isActive,
                image: null,
            })
            setFileImage(selectedData.image)
        }
    }, [formMode === 'update']);

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "page" })}
                        placeholder="E.g : Home, Categories"
                        name="pageName"
                        control={control}
                        error={errors?.pageName}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    <CustomSelect
                        options={adPlacementList}
                        label={formatMessage({ id: "placement" })}
                        name="placement"
                        control={control}
                        error={errors.placement}
                        rules={{ required: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "title" })}
                        name="title"
                        control={control}
                        error={errors?.title}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "detail" })}
                        name="detail"
                        control={control}
                        minRows={6}
                    />
                </Grid> */}
                <Grid item xs={12} >
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        {...register('image', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                        })}
                        label={formatMessage({ id: "image" })}
                        error={errors?.image}
                        defaultFileUrl={fileImage}
                        defaultFileName={''}
                        onClear={() => {
                            setValue(`image`, null);
                            clearErrors(`image`);
                        }}
                        reSized={false}
                        // onChange={(file: any) => setValue('image', file)}
                    />
                </Grid>
                <Grid item xs={12} >
                <CustomTextBox
                    label={formatMessage({ id: "sequence" })}
                    name="sequence"
                    control={control}
                    error={errors?.sequence}
                    rules={validationRule.textbox({ type: "number" })}
                />
                </Grid>

                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="isActive"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;
