import React from "react";
import { Controller, ControllerProps, FieldError } from "react-hook-form";
import { makeStyles } from 'tss-react/mui';
import dayjs from "dayjs";

import KeyboardTimePicker, { TimePickerProps } from '@mui/lab/TimePicker';
import TextField from '@mui/material/TextField';
import FormControl from "@mui/material/FormControl";

import { FormErrorMessage } from "components/forms";


const useStyles = makeStyles()(() => ({
	root: {
		paddingRight: "3px",
	},
	input: {
		marginRight: "0px !important",
		paddingLeft: "8px",
	},
})
);

interface Props {
	name: string;
	rules?: ControllerProps["rules"];
	defaultValue?: any;
	control: ControllerProps["control"];
	error?: any;
	onChangeDate?: (date: any) => void;
	formLabel?: string;
}

const CustomTimePicker = React.memo((props: Props & Partial<TimePickerProps<any>>) => {
	const {
		name,
		rules,
		defaultValue,
		control,
		error,
		onChangeDate,
		formLabel,
		...rest
	} = props;
	const { classes } = useStyles();

	return (
		<FormControl fullWidth>
			{formLabel && (
				<span className="text-13 font-medium" style={{ marginBottom: "8px" }}>
					{formLabel}
				</span>
			)}

			<Controller
				name={name}
				control={control}
				rules={rules}
				defaultValue={defaultValue ?? null}
				render={({ field: { ref, value, onChange: _onChange, ...fieldRest } }) => {
					return (
						<KeyboardTimePicker
							// variant="inline"
							// inputVariant="outlined"
							// fullWidth
							// size="small"
							// placeholder="hh:mm"
							// mask="__:__ _M"
							// autoOk={true}
							// error={error ? true : false}
							// minutesStep={5}
							// {...rest}
							// {...fieldRest}
							// InputProps={{
							// 	classes: {
							// 		root: classes.root,
							// 		input: classes.input,
							// 	},
							// }}
							// keyboardIcon={<ClockIcon color="primary" fontSize="small" />}
							// InputAdornmentProps={{ position: "end" }}
							// KeyboardButtonProps={{ size: "small", color: "primary" }}
							// onChange={(date, value) => {
							// 	if (value ? dayjs(value, "hh:mm A", true).isValid() : false) {
							// 		fieldRest.onChange(date);
							// 		if (onChangeDate) {
							// 			onChangeDate(date);
							// 		}
							// 	}
							// }}
							// onBlur={(e) => {
							// 	let value = e.target.value;
							// 	if (!dayjs(value, "hh:mm A", true).isValid()) {
							// 		fieldRest.onChange(dayjs(fieldRest?.value?.toString()));
							// 	}
							// 	if (fieldRest?.onBlur) {
							// 		fieldRest.onBlur();
							// 	}
							// }}
							label={rules?.required ? `${props.label} *` : props.label}
							minutesStep={5}
							value={value}
							onChange={(date:any, value:any) => {
								if (value ? dayjs(value, "hh:mm A", true).isValid() : false) {
									_onChange(date);
									if (onChangeDate) {
										onChangeDate(date);
									}
								}
							}}
							renderInput={(params:any) => (
								<TextField
									size="small"
									variant="outlined"
									fullWidth
									placeholder="DD-MM-YYYY"
									{...params}
									onBlur={(e) => {
										let inputValue = e.target.value;
										if (!dayjs(inputValue, "hh:mm A", true).isValid()) {
											_onChange(dayjs(dayjs(value).format('yyyy-mm-dd hh:mm A')));
										}
										if (fieldRest?.onBlur) {
											fieldRest.onBlur();
										}
									}}
									error={error ? true : false}
								/>
							)}
							{...fieldRest}
							{...rest}
						/>
					);
				}}
			/>

			<FormErrorMessage error={error} />
		</FormControl>
	);
}
);

const TimePicker = React.memo((props: Omit<TimePickerProps<any>, 'renderInput'>) => {
	// const TimePicker = React.memo((props: TimePickerProps) => {
	return (
		<KeyboardTimePicker
			// variant="inline"
			// inputVariant="outlined"
			// fullWidth
			// size="small"
			// placeholder="hh:mm"
			// mask="__:__ _M"
			// InputProps={{
			// 	endAdornment: (
			// 		<InputAdornment position="end">
			// 			<ClockIcon color="primary" />
			// 		</InputAdornment>
			// 	),
			// }}
			// autoOk={true}
			// minutesStep={5}
			// keyboardIcon={<ClockIcon color="primary" />}
			// onChange={(date, value) => {
			// 	if (value ? dayjs(value, "hh:mm A", true).isValid() : false) {
			// 		props.onChange(date);
			// 	}
			// }}
			// onBlur={(e) => {
			// 	let value = e.target.value;
			// 	if (!dayjs(value, "hh:mm A", true).isValid()) {
			// 		props.onChange(dayjs(props?.value?.toString()));
			// 	}
			// 	if (props?.onBlur) {
			// 		props.onBlur(e);
			// 	}
			// }}

			{...props}
			renderInput={(params:any) => (
				<TextField
					size="small"
					variant="outlined"
					fullWidth
					// size="small"
					placeholder="DD-MM-YYYY"
					label={props.label}
					{...params}
				// {...rest}
				// style={{ ...style }}
				/>
			)}
		/>
	);
});

export { CustomTimePicker, TimePicker };
