import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { images } from 'utils/constants';

export default function Logo({ disabledLink = false, sx } :any) {
  const logo = <Box component="img" src={images.logo} sx={{ 
    //  height: 64,
      padding:'9px', ...sx }} />

  if (disabledLink) {
    return <>{logo}</>;
  }

  return logo;
}
