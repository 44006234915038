import { MASTER_API_ENDPOINTS } from "redux/masterApiEndpoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any;
}

export const masterServices = {

  createRole: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_ROLE, body),
  updateRole: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_ROLE, body),
  getAllRole: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_ROLE, body),
  deleteRole: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_ROLE, body),

  createSkill: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_SKILL, body),
  updateSkill: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_SKILL, body),
  getAllSkill: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SKILL, body),
  deleteSkill: (uuid:string) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SKILL + "/" + uuid),

  createSpokenLanguage: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_SPOKEN_LANGUAGE, body),
  updateSpokenLanguage: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_SPOKEN_LANGUAGE, body),
  getAllSpokenLanguage: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_SPOKEN_LANGUAGE, body),
  deleteSpokenLanguage: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_SPOKEN_LANGUAGE, body),

  createBlogCategory: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_BLOG_CATEGORY, body),
  updateBlogCategory: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_BLOG_CATEGORY, body),
  getAllBlogCategory: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_BLOG_CATEGORY, body),
  deleteBlogCategory: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_BLOG_CATEGORY, body),

  createBlogTag: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_BLOG_TAG, body),
  updateBlogTag: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_BLOG_TAG, body),
  getAllBlogTag: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_BLOG_TAG, body),
  deleteBlogTag: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_BLOG_TAG, body),

  createPropertyTag: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_PROPERTY_TAG, body),
  updatePropertyTag: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_PROPERTY_TAG, body),
  getAllPropertyTag: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_PROPERTY_TAG, body),
  deletePropertyTag: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_PROPERTY_TAG, body),

  createAmenities: (body: ParamsState) => new AxiosService().postForm(MASTER_API_ENDPOINTS.CREATE_AMENITIES_TAG, body),
  updateAmenities: (body: ParamsState) => new AxiosService().patchForm(MASTER_API_ENDPOINTS.UPDATE_AMENITIES_TAG, body),
  getAllAmenities: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_AMENITIES_TAG, body),
  deleteAmenities: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_AMENITIES_TAG, body),

  createCategories: (body: ParamsState) => new AxiosService().postForm(MASTER_API_ENDPOINTS.CREATE_CATEGORY, body),
  updateCategories: (body: ParamsState) => new AxiosService().patchForm(MASTER_API_ENDPOINTS.UPDATE_CATEGORY, body),
  getAllCategories: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_CATEGORY, body),
  deleteCategories: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_CATEGORY, body),

  createAreaUnit: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_AREA_UNIT, body),
  updateAreaUnit: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_AREA_UNIT, body),
  getAllAreaUnit: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_AREA_UNIT, body),
  deleteAreaUnit: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_AREA_UNIT, body),

  createPropertyFace: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_PROPERTY_FACE, body),
  updatePropertyFace: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_PROPERTY_FACE, body),
  getAllPropertyFace: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_PROPERTY_FACE, body),
  deletePropertyFace: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_PROPERTY_FACE, body),

  createPropertyStatus: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_PROPERTY_STATUS, body),
  updatePropertyStatus: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_PROPERTY_STATUS, body),
  getAllPropertyStatus: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_PROPERTY_STATUS, body),
  deletePropertyStatus: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_PROPERTY_STATUS, body),
  
  createRoadType: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.CREATE_ROAD_TYPE, body),
  updateRoadType: (body: ParamsState) => new AxiosService().patch(MASTER_API_ENDPOINTS.UPDATE_ROAD_TYPE, body),
  getAllRoadType: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_ROAD_TYPE, body),
  deleteRoadType: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_ROAD_TYPE, body),

  getAllContact: (body: ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.LIST_CONTACT, body),
  deleteContact: (body: ParamsState) => new AxiosService().delete(MASTER_API_ENDPOINTS.DELETE_CONTACT, body),

  filesUpload: (body:ParamsState) => new AxiosService().post(MASTER_API_ENDPOINTS.FILE_UPLOAD,body),

};
