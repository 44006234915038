
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import {  TableEditButton, DeleteButton, TableButtonGroup } from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { filterTypes } from 'utils/constants/default';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { userColumns } from 'utils/constants/tableColumn/column';

const User: React.FC = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { tableData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            tableData: masterPaginationReducer[masterPaginationServices.user].data,
            loading: masterPaginationReducer[masterPaginationServices.user].loading,
        }),
        shallowEqual
    );

    const { items , meta: { totalItems } } = tableData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        dispatch(getMasterPaginationData(masterPaginationServices.user, params));
    }

    const columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return (
                    <TableButtonGroup>
                        <TableEditButton
                            tooltipLabel="Edit User"
                            onClick={() => handleRowClick(items[tableMeta.rowIndex])}
                        />
                        <DeleteButton
                            onDelete={() => onDeleteData(items[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                )
            }
        }
    }

    function handleRowClick(rowData: any) {
        navigate(`/admin/users/update/${rowData?.uuid}`, { ...rowData });
    }

    function onDeleteData(data: any) {
        const parms = {
            userId: data.id
        }
        setDeleteLoading(true);
        // services.deleteUser(parms)
        //     .then((res:any) => {
        //         setDeleteLoading(false);
        //         if (res.data?.succeeded) {
        //             onApiCall();
        //             toastMessage(formatMessage({ id: "delete-message" }));
        //         } else {
        //             toastMessage(res.data?.message, 'error');
        //         }
        //     })
        //     .catch((err) => {
        //         setDeleteLoading(false);
        //         toastMessage(err?.data?.message || 'Something Wrong', 'error');
        //     })
    }

    return (
        <PageLayout title={formatMessage({ id: "customers" })} hideToolbar>
            <CustomTable
                columns={[...userColumns(formatMessage),columnAction]}
                tableData={items}
                tableState={tableState}
                rowsCount={totalItems}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />
        </PageLayout>
    )
}

export default User;
