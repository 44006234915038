import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Select } from 'components/forms';
import { blogStatusList } from 'utils/constants';
import { useIntl } from 'react-intl';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { useEffect, useState } from 'react';

export default function UpdateStatus({ open, handleClose, defaultData, refreshData }: any) {
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const [status, setStatus] = useState<any>(null);

    useEffect(() => {
        if (defaultData) {
            setStatus(blogStatusList.find(item => item.value == defaultData?.status))
        }

    }, [defaultData])

    function onSubmit() {
        if(status.value){
            services.updateStatusBlog({
                uuid: defaultData.uuid,
                status: status.value
            })
            .then((res: any) => {
                handleClose();
                refreshData()
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
        }else{
            toastMessage('Please select status', 'error');
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Update Blog Status</DialogTitle>
                <DialogContent>
                    <Select
                        options={blogStatusList}
                        label={formatMessage({ id: "status" })}
                        disableClearable
                        value={status}
                        fullWidth
                        onChange={(_, data) => {
                            setStatus(data)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error" variant='contained'>Cancel</Button>
                    <Button onClick={onSubmit} variant='contained'>Update Status</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}