import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

// material
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate } from 'utils/constants';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const navigate = useNavigate();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm<any>({ mode: 'all', defaultValues: { isActive: true } });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState<any>(null);
    const [fileImage, setFileImage] = useState('')


    function onSubmit(data: any) {
        setLoading(true);
        data.logo = data?.logo?.[0];
        if (formType === 'update') data.uuid = editData.uuid;
        const apiFunc = formType === 'create' ? 'createAccount' : 'updateAccount';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'account' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getAccountByUuid(uuid)
                .then((res: any) => {
                    setLoading(false);
                    const response = res.result;
                    setEditData(response)
                })
                .catch((err: any) => {
                    setLoading(false);
                    toastMessage(err?.data?.message || 'Something Wrong', 'error');
                })
        }

    }, [formType === 'update', uuid]);


    useEffect(() => {
        if (editData) {
            reset({
                name: editData.name,
                address: editData.address,
                detail: editData.detail,
                phone: editData.phone,
                logo: null,
                email: editData.email,
            })
            setFileImage(editData.logo)
        }
    }, [editData])

    return (
        <FormLayout formType={formType} title={formatMessage({ id: "account" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "general-information" })}>
                <Grid container spacing={2}>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "name" })}
                            name="name"
                            control={control}
                            error={errors?.name}
                            rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "email" })}
                            name="email"
                            control={control}
                            error={errors?.email}
                            rules={validationRule.textbox({ required: true, type: "email" })}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "detail" })}
                            name="detail"
                            control={control}
                            error={errors?.detail}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address" })}
                            name="address"
                            control={control}
                            error={errors?.address}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone" })}
                            name="phone"
                            control={control}
                            error={errors?.phone}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            label={formatMessage({ id: "image" })}
                            error={errors?.logo}
                            {...register('logo', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                            })}
                            defaultFileUrl={fileImage}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`image`, null);
                                clearErrors(`image`);
                            }}
                        onChange={(file:any) => setValue('logo' , file)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomCheckBox
                            name="isActive"
                            label={formatMessage({ id: "is-active" })}
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomCheckBox
                            name="isPublish"
                            label={formatMessage({ id: "publish" })}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>



        </FormLayout>
    )
}

export default Form;