import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { colorList } from 'assets/styles/mui';

interface Props extends IconButtonProps {
    tooltipLabel?: string;
    imageStyle?: React.CSSProperties;
    style?: React.CSSProperties;
}

const iconStyle = {
    padding: 3,
    borderRadius: 25,
    borderStyle: 'solid',
    borderColor: 'inherit',
    background: colorList.primaryLight,
    color: colorList.primaryDark
}

const CreateButton = React.memo((props: Props) => {
    const { tooltipLabel, style = {}, imageStyle, ...rest } = props;

    return (
        <Tooltip title={tooltipLabel ?? ""}>
            <IconButton
                size="small"
                color="primary"
                style={{ ...iconStyle, ...style }}
                {...rest}
            >
                <AddIcon htmlColor="inherit" />
            </IconButton>
        </Tooltip>
    )
});

export { CreateButton };