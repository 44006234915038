import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// material
import { Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { FileAcceptData, fileValidate, masterLookupServices } from 'utils/constants';
import { services } from 'redux/services';
import { RootReducerState } from 'utils/types';
import { getMasterLookupData } from 'redux/actions';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const navigate = useNavigate();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const [editData, setEditData] = useState<any>(null);
    const [photoUrl, setPhotoUrl] = useState<any>(null);

    const { roleData } = useSelector(
        ({ masterLookupReducer }: RootReducerState) => {
            return ({
                roleData: masterLookupReducer[masterLookupServices.getUserLookup].data
            })
        },
        shallowEqual
    );


    useEffect(() => {
        setLoading(false);
        dispatch(getMasterLookupData(masterLookupServices.getUserLookup));
    }, [])

    useEffect(() => {
        if (roleData?.items?.length) {
            setRoles(roleData?.items.map((item: any) => ({ label: item.name, value: item.id })))
        }
    }, [roleData?.items])

    function onSubmit(data: any) {
        setLoading(true);
        data.roles = data?.roles?.map((item: any) => +item.value);
        if (formType === 'update') {
            data.uuid = editData.uuid;
            if (!data.password) delete data.password;
        }
        const apiFunc = formType === 'create' ? 'createAdmin' : 'updateAdmin';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'admin' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                if (err?.data?.statusCode === 422) toastMessage(JSON.stringify(err?.data?.error), 'error');
                else toastMessage((err?.data?.message), 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getAdminByUuid(uuid)
                .then((res: any) => {
                    setLoading(false);
                    const response = res.result;
                    setEditData(response)
                })
                .catch((err: any) => {
                    setLoading(false);
                    toastMessage(err?.data?.message || 'Something Wrong', 'error');
                })
        }

    }, [formType === 'update', uuid]);


    useEffect(() => {
        if (editData) {
            reset({
                firstName: editData.firstName,
                middleName: editData.middleName,
                lastName: editData.lastName,
                userName: editData.userName,
                email: editData.email,
                verify: editData.verify,
                roles: roles?.filter((item: any) => editData?.roles?.map((l: any) => +l.id)?.includes(+item.value)),
            })
            setPhotoUrl(editData.photo)
        }
    }, [roles, editData])

    return (
        <FormLayout formType={formType} title={formatMessage({ id: "admin" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <SectionLayout title={formatMessage({ id: "general-information" })}>
                <Grid container spacing={2}>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "first-name" })}
                            name="firstName"
                            control={control}
                            error={errors?.firstName}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "last-name" })}
                            name="lastName"
                            control={control}
                            error={errors?.lastName}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "username" })}
                            name="userName"
                            control={control}
                            error={errors?.userName}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "email" })}
                            name="email"
                            control={control}
                            error={errors?.email}
                            rules={validationRule.textbox({ required: true, type: "email" })}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "password" })}
                            name="password"
                            control={control}
                            error={errors?.password}
                            type="password"
                            rules={formType === 'create' ? {
                                ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                validate: (value: any) => (watch('confirmation_password') ? (watch('confirmation_password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                            } : {}}
                            autoComplete="off"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                                        <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                                            <InfoIcon color="primary" />
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "confirm-password" })}
                            name="confirmation_password"
                            control={control}
                            error={errors?.confirmation_password}
                            type="password"
                            rules={formType === 'create' ? {
                                ...validationRule.textbox({ required: true, type: 'passwordPolicy' }),
                                validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                            } : {}}
                            autoComplete="off"
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={roles}
                            label={formatMessage({ id: "role" })}
                            name="roles"
                            control={control}
                            error={errors.roles}
                            rules={{ required: true }}
                            multiple
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            {...register('photo', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                            })}
                            label="Photo"
                            defaultFileUrl={photoUrl}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`photo`, null);
                                clearErrors(`photo`);
                            }}
                            onChange={(file: any) => setValue('photo', file)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomCheckBox
                            name="verify"
                            label={formatMessage({ id: "verify" })}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>



        </FormLayout>
    )
}

export default Form;