import { services } from "redux/services";

const check = <T extends string>(
	collection: Record<T, keyof typeof services>
) => collection;

export const masterLookupServices = check({
	getPropertyLookup:'getPropertyLookup',
	getUserLookup:'getAllRole',
	getBlogLookup:'getBlogLookup',
	getAddressLookup:'getAddressLookup',
});