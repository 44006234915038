import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import Axios from "axios";

import { styled } from "@mui/material/styles";
import {
  Container,
  Stack,
  InputAdornment,
  Typography,
  Card,
  Link as MuiLink,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { CustomCheckBox, CustomTextBox } from "components/forms";
import Person from "@mui/icons-material/Person";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { useToastMessage } from "hooks";
import { RootReducerState } from "utils/types";
import { validationRule } from "utils/global";
import { API_ENDPOINTS } from "redux/apiEndPoints";
import { OtpConstant } from "utils/constants";
import { HoverLoader } from "components";
import { Helmet } from "react-helmet-async";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  // padding: theme.spacing(12, 0)
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 550,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.shadows,
  backgroundColor: theme.palette.background.default,
}));

export default function Register() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { toastMessage } = useToastMessage();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({ mode: "all" });
  const { authData } = useSelector(
    ({ auth }: RootReducerState) => ({
      authData: auth.data,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (authData?.accessToken) {
      navigate("/admin/dashboard");
    }
  }, [authData]);

  async function onSubmit(data: any) {
    setLoading(true);
    Axios.post(`${API_ENDPOINTS.REGISTER}`, data)
      .then((res: any) => {
        setLoading(false);
        toastMessage("Otp has sent to your mail.", "success");
        navigate(
          `/auth/verify?email=${res?.data?.result.email}&otpType=${OtpConstant.register}`
        );
      })
      .catch((err) => {
        setLoading(false);
        const message =
          JSON.stringify(err?.response?.data?.error) ?? "Opps something wrong!";
        toastMessage(message, "error");
      });
  }

  return (
    <Container maxWidth="xl" sx={{ background: "#F5F5F5" }}>
      <Helmet>
        <title>Agent Registration | Gharawas - Join as an Agent Today!</title>
        <meta
          name="description"
          content="Register as an agent on Gharawas, the leading real estate platform. Join our network, showcase your properties, and connect with potential buyers and sellers."
        />
        <meta
          name="keywords"
          content="Gharawas, real estate, agent register, property listings, property showcase, buyer-seller connections"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Gharawas" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {loading && <HoverLoader />}
      <ContentStyle>
        <Card sx={{ p: 2 }}>
          <Typography variant="h4" gutterBottom>
            Sign Up
          </Typography>

          <Typography variant="body2" sx={{ mb: 2 }}>
            Already have an account?{" "}
            <Link
              to="/auth/login"
              style={{ textDecoration: "none", color: "#6327e4", fontSize: 14 }}
            >
              Sign In
            </Link>
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <CustomTextBox
                label={formatMessage({ id: "full-name" })}
                name="fullName"
                control={control}
                error={errors?.fullName}
                rules={validationRule.textbox({
                  required: true,
                  type: "textWithSpace",
                })}
              />

              <CustomTextBox
                label={formatMessage({ id: "email" })}
                error={errors.email}
                name="email"
                rules={{ required: true }}
                control={control}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span className="inpt_back">
                        <Person color="primary" />
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <CustomTextBox
                label={formatMessage({ id: "phone" })}
                name="phone"
                control={control}
                error={errors?.phone}
                rules={validationRule.textbox({ type: "contact" })}
              /> */}
              <CustomTextBox
                label="Password"
                type={showPassword ? "text" : "password"}
                error={errors.password}
                name="password"
                control={control}
                margin="normal"
                rules={{
                  ...validationRule.textbox({ required: true, minLength: 6 }),
                  validate: (value: any) =>
                    (watch("confirmation_password")
                      ? watch("confirmation_password") === value
                      : true) ||
                    formatMessage({
                      id: "password-confirm-validation-message",
                    }),
                }}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MuiLink
                        href="#"
                        underline="none"
                        onClick={() => {
                          setShowPassword((prev) => !prev);
                        }}
                      >
                        {!showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </MuiLink>
                    </InputAdornment>
                  ),
                }}
              />
              <CustomTextBox
                label={formatMessage({ id: "confirm-password" })}
                name="confirmation_password"
                control={control}
                error={errors?.confirmation_password}
                type={showPassword ? "text" : "password"}
                rules={{
                  ...validationRule.textbox({ required: true, minLength: 6 }),
                  validate: (value: any) =>
                    (watch("password") ? watch("password") === value : true) ||
                    formatMessage({
                      id: "password-confirm-validation-message",
                    }),
                }}
                autoComplete="off"
              />
              <CustomCheckBox
                name="type"
                label={formatMessage({ id: "is-company" })}
                control={control}
              />
              {watch("type") == 1 && (
                <>
                  <CustomTextBox
                    label="Company Name"
                    error={errors.companyName}
                    rules={{ required: watch("type") == 1 }}
                    name="companyName"
                    control={control}
                    margin="normal"
                  />
                  <CustomTextBox
                    label="Company Email"
                    error={errors.companyEmail}
                    name="companyEmail"
                    rules={{ required: watch("type") == 1 }}
                    control={control}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span className="inpt_back">
                            <Person color="primary" />
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </>
              )}
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ my: 2 }}
            >
              Register
            </LoadingButton>
          </form>
          <Stack alignItems="center" textAlign="center" sx={{ my: 1 }}>
            <Typography variant="body2">
              By signing up, I agree to{" "}
              <a href="https://gharawas.com/terms-of-service" target="_blank">
                Terms of Service
              </a>{" "}
              and{" "}
              <a href="https://gharawas.com/privacy-policy" target="_blank">
                Privacy Policy
              </a>
              .
            </Typography>
          </Stack>
        </Card>
      </ContentStyle>
    </Container>
  );
}
