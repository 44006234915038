import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// material
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomFileUpload, CustomSelect, CustomTextArea, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import CustomTextEditor from 'components/forms/CustomTextEditor';
import { FileAcceptData, fileValidate } from 'utils/constants';

const Form: React.FC = () => {
    const { formType, uuid }: any = useParams<any>();
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [updateId, setUpdateId] = useState<number>(0);
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState<any>(null);
    const [fileImage, setFileImage] = useState({
        image: '',
    })


    function onSubmit(data: any) {
        setLoading(true);
        data.image = data?.image?.[0];
        if (formType === 'update') {
            data.id = updateId;
            data.uuid = uuid;
        }
        const apiFunc = formType === 'create' ? 'createPage' : 'updatePage';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                reset();
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'page' }) }));
                navigate(-1)
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (uuid) {
            setLoading(true);
            services.getPageByUuid(uuid)
                .then((res: any) => {
                    const response = res.result;
                    setUpdateId(+response.id);
                    setDefaultData(response);
                    setFileImage({ image: response.image })
                })
                .catch((err: any) => {
                    toastMessage(err?.data?.message || 'Something Wrong', 'error');
                }).finally(() => setLoading(false))
        }

    }, [formType === 'update', uuid]);

    useEffect(() => {
        if (defaultData) {
            delete defaultData.photo;
            reset({
                url: defaultData.url,
                title: defaultData.title,
                subTitle: defaultData.subTitle,
                shortDescription: defaultData.shortDescription,
                description: defaultData.description,
                isActive: defaultData.isActive,
                metaTitle: defaultData.metaTitle,
                metaDescription: defaultData.metaDescription,
                metaKeyword: defaultData.metaKeyword,
                image: null,
            });
        }
    }, [defaultData])


    return (
        <FormLayout formType={formType} title={formatMessage({ id: "page" })} onSubmit={handleSubmit(onSubmit)}>
            {loading && <HoverLoader />}
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <SectionLayout title={formatMessage({ id: "page-information" })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "url" })}
                                    name="url"
                                    control={control}
                                    error={errors?.url}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "title" })}
                                    name="title"
                                    control={control}
                                    error={errors?.title}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "sub-title" })}
                                    name="subTitle"
                                    control={control}
                                    error={errors?.subTitle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "short-description" })}
                                    name="shortDescription"
                                    control={control}
                                    error={errors?.shortDescription}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomTextEditor
                                    name="description"
                                    defaultValue={watch('description')}
                                    error={errors?.description}
                                    onChange={(editor:any) => setValue('description', editor)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomCheckBox
                                    name="isActive"
                                    label={formatMessage({ id: "is-active" })}
                                    control={control}
                                />
                            </Grid>
                        </Grid>
                    </SectionLayout>
                </Grid>
                <Grid item xs={4}>
                    <SectionLayout title={formatMessage({ id: "image" })}>
                        <Grid item xs={12} >
                            <CustomFileUpload
                                accept={FileAcceptData.IMAGES}
                                {...register('image', {
                                    validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                                })}
                                label={formatMessage({ id: "image" })}
                                error={errors?.image}
                                defaultFileUrl={fileImage.image}
                                defaultFileName={''}
                                onClear={() => {
                                    setValue(`image`, null);
                                    clearErrors(`image`);
                                }}
                                onChange={(file: any) => setValue('image', file)}
                            />
                        </Grid>
                    </SectionLayout>
                    <SectionLayout title={formatMessage({ id: "seo-information" })}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "meta-title" })}
                                    name="metaTitle"
                                    control={control}
                                    error={errors?.metaTitle}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextArea
                                    formLabel={formatMessage({ id: "meta-description" })}
                                    name="metaDescription"
                                    control={control}
                                    minRows={6}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextBox
                                    label={formatMessage({ id: "meta-keyword" })}
                                    name="metaKeyword"
                                    control={control}
                                    error={errors?.metaKeyword}
                                />
                            </Grid>
                        </Grid>
                    </SectionLayout>
                </Grid>
            </Grid>
        </FormLayout>
    )
}

export default Form;