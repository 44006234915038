import axios, { AxiosPromise } from "axios";
import { API_ENDPOINTS } from "redux/apiEndPoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any
}

export const appServices = {
  logoutUser: (params: ParamsState): AxiosPromise => axios.get(API_ENDPOINTS.LOGOUT, { params }),
  dashboard: () => new AxiosService().get(API_ENDPOINTS.DASHBOARD),
  profile: () => new AxiosService().get(API_ENDPOINTS.PROFILE),

  createProperty: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_PROPERTY, body),
  updateProperty: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_PROPERTY, body),
  getAllProperty: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_PROPERTY, body),
  getPropertyByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_PROPERTY_BY_UUID + '/' + uuid),
  deleteProperty: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_PROPERTY + '/' + uuid),
  getPropertyLookup: () => new AxiosService().get(API_ENDPOINTS.PROPERTY_LOOKUP),
  updatePropertyFeature: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.UPDATE_PROPERTY_FEATURE, body),
  uploadPopertyFile: (body:ParamsState) => new AxiosService().postForm(API_ENDPOINTS.UPLOAD_PROPERTY_FILE_,body),
  deletePopertyFile: (body:ParamsState) => new AxiosService().post(API_ENDPOINTS.DELETE_PROPERTY_FILE,body),

  createBlog: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_BLOG, body),
  updateBlog: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_BLOG, body),
  getAllBlog: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_BLOG, body),
  getBlogByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_BLOG_BY_UUID + '/' + uuid),
  deleteBlog: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_BLOG + '/' + uuid),
  updateStatusBlog: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_BLOG_STATUS, body),
  getBlogLookup: () => new AxiosService().get(API_ENDPOINTS.BLOG_LOOKUP),

  createBanner: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_BANNER, body),
  updateBanner: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_BANNER, body),
  getAllBanner: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_BANNER, body),
  getBannerByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_BANNER_BY_UUID + '/' + uuid),
  deleteBanner: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_BANNER + '/' + uuid),

  createUser: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_USER, body),
  updateUser: (body: ParamsState) => new AxiosService().patch(API_ENDPOINTS.UPDATE_USER, body),
  getAllUser: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_USER, body),
  getUserByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_USER_BY_UUID + '/' + uuid),
  deleteUser: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_USER + '/' + uuid),
  getUserLookup: () => new AxiosService().get(API_ENDPOINTS.USER_LOOKUP),
  updateUserFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_USER_FLAG, body),

  createOwner: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_OWNER, body),
  updateOwner: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_OWNER, body),
  getAllOwner: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_OWNER, body),
  getOwnerByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_OWNER_BY_UUID + '/' + uuid),
  deleteOwner: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_OWNER + '/' + uuid),
  updateOwnerFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_OWNER_FLAG, body),

  createAddress: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_ADDRESS, body),
  updateAddress: (body: ParamsState) => new AxiosService().patch(API_ENDPOINTS.UPDATE_ADDRESS, body),
  getAllAddress: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_ADDRESS, body),
  getAddressByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_ADDRESS_BY_UUID + '/' + uuid),
  deleteAddress: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_ADDRESS + '/' + uuid),
  getAddressLookup: () => new AxiosService().get(API_ENDPOINTS.ADDRESS_LOOKUP),

  createPartner: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_PARTNER, body),
  updatePartner: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_PARTNER, body),
  getAllPartner: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_PARTNER, body),
  deletePartner: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_PARTNER, body),

  createAdvertisement: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_ADVERTISEMENT, body),
  updateAdvertisement: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_ADVERTISEMENT, body),
  getAllAdvertisement: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_ADVERTISEMENT, body),
  deleteAdvertisement: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_ADVERTISEMENT, body),

  createAdmin: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_ADMIN, body),
  updateAdmin: (body: ParamsState) => new AxiosService().patch(API_ENDPOINTS.UPDATE_ADMIN, body),
  getAllAdmin: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_ADMIN, body),
  getAdminByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_ADMIN_BY_UUID + '/' + uuid),
  deleteAdmin: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_ADMIN + '/' + uuid),
  updateAdminProfile: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_ADMIN_PROFILE, body),

  createAccount: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_ACCOUNT, body),
  updateAccount: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_ACCOUNT, body),
  getAllAccount: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_ACCOUNT, body),
  getAccountByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_ACCOUNT_BY_UUID + '/' + uuid),
  deleteAccount: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_ACCOUNT + '/' + uuid),

  createPage: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_PAGE, body),
  updatePage: (body: ParamsState) => new AxiosService().patchForm(API_ENDPOINTS.UPDATE_PAGE, body),
  getAllPage: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_PAGE, body),
  getPageByUuid: (uuid: string) => new AxiosService().get(API_ENDPOINTS.GET_PAGE_BY_UUID + '/' + uuid),
  deletePage: (uuid: string) => new AxiosService().delete(API_ENDPOINTS.DELETE_PAGE + '/' + uuid),

  createOrUpdateSetting: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_SETTING, body),
  getSetting: () => new AxiosService().get(API_ENDPOINTS.GET_SETTING),

  getUserLogout: (): AxiosPromise => axios.post(API_ENDPOINTS.LOGOUT),

}