import { images } from "utils/constants";

export function findCommonItems(arr1: any, arr2: any) {
    return arr1?.some((v: any) => arr2?.indexOf(v) !== -1);
}

export const blobToFile = (theBlob: Blob, fileName:string): File => {       
    return new File(
        [theBlob as any], // cast as any
        fileName, 
        {
            lastModified: new Date().getTime(),
            type: theBlob.type 
        }
    )
}

export function getUpdateImageFormat(objectUrl:any) {
    let previewImage;
    const split = objectUrl?.split('.');
    const fileName = objectUrl?.split('/')?.pop();
    if (['pdf', 'xls', 'xlsx', 'docx'].includes(split?.pop())) {
        previewImage = images.document;
    } else {
        previewImage = objectUrl;
    }
    return { objectUrl, previewImage ,fileName};
}

export function getUploadImageFormat(objectUrl:any) {
    let previewImage;
    if (objectUrl) {
        const split = objectUrl?.name?.split('.');
        if (['pdf', 'xls', 'xlsx', 'docx'].includes(split?.pop())) {
            objectUrl = URL.createObjectURL(objectUrl);
            previewImage = images.document;
        } else {
            objectUrl = URL.createObjectURL(objectUrl);
            previewImage = URL.createObjectURL(objectUrl);
        }
    } 

    return { objectUrl, previewImage };
}