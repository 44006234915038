import React from 'react';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { Controller, ControllerProps, FieldError } from "react-hook-form";

import { FormErrorMessage, } from "components/forms";
import TextareaAutosize, { TextareaAutosizeProps } from '@mui/material/TextareaAutosize';
import { styled } from '@mui/material/styles';

interface Props {
    name: string;
    rules?: ControllerProps["rules"];
    defaultValue?: any;
    control: ControllerProps["control"];
    error?: any;
    margin?: FormControlProps["margin"];
    containerStyle?: React.CSSProperties;
    formLabel?: string;
    onChangeValue?: (value: string) => void;
}

const LabelStyle = styled('div')(({ theme }: any) => ({
    marginBottom: -17,
    zIndex: 9,
    marginLeft: 10,
    background: theme.palette.common.white,
    color: theme.palette.action.active,
    padding: 5,
    display: "flex"
}));

const TextAreaAutosize = styled(TextareaAutosize)(({ theme }: any) => ({
    padding: 20,
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.common.white,
    borderRadius: 8,
    width: '100%',
    fontFamily:'Public Sans,sans-serif'
}));

const CustomTextArea = React.memo(((props: Props & TextareaAutosizeProps) => {
    const { margin, name, control, rules, defaultValue, error, containerStyle, formLabel, onChangeValue, ...rest } = props;
    return (
        <FormControl margin={margin} sx={{ width: "100%", alignItems: "flex-start", ...containerStyle }}>
            {formLabel && <LabelStyle>{formLabel}</LabelStyle>}
            <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue ?? null}
                render={({ field: { ref, onChange, ...fieldRest } }) => (
                    <TextAreaAutosize
                        {...fieldRest}
                        {...rest}
                        onChange={e => {
                            onChange(e);
                            if (onChangeValue) {
                                onChangeValue(e.target.value);
                            }
                        }}
                        value={fieldRest.value ?? ""}
                    />
                )}
            />

            <FormErrorMessage error={error} />
        </FormControl>
    )
}));

interface TextAreaState {
    formLabel?: string;
    containerStyle?: React.CSSProperties;
}

const TextArea = React.memo((props: TextareaAutosizeProps & TextAreaState) => {
    const { formLabel, containerStyle, ...rest } = props;

    return (
        <div style={{ ...containerStyle }}>
            {formLabel && <span className="text-11 font-medium" style={{
                marginBottom: -10,
                zIndex: 9,
                marginLeft: 10,
                background: "#fff",
                padding: 5,
                display: "flex"
            }}>{formLabel}</span>}
            <TextareaAutosize
                {...rest}
                value={props.value ?? ""}
            />
        </div>
    )
})

export { CustomTextArea, TextArea };