
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { TableEditButton, DeleteButton, TableButtonGroup, TableViewButton, TableFlagButton } from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import { propertyColumns } from 'utils/constants/tableColumn/column';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';

const Property: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const dispatch = useDispatch();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData: masterPaginationReducer[masterPaginationServices.property].data,
            loading: masterPaginationReducer[masterPaginationServices.property].loading,
        }),
        shallowEqual
    );

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.property, params));
    }

    const columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return (
                    <TableButtonGroup>
                        <TableEditButton
                            tooltipLabel="Edit"
                            onClick={() => handleRowClick(userData.items[tableMeta.rowIndex])}
                        />
                        <DeleteButton
                            tooltipLabel="Delete"
                            onDelete={() => onDeleteData(userData.items[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                )
            }
        }
    }

    function handleRowClick(rowData: any) {
        navigate(`${pathname}/update/general/${rowData?.uuid}`, { ...rowData });
    }

    function onDeleteData(data: any) {
        setDeleteLoading(true);
        services.deleteProperty(data.uuid)
            .then((res: any) => {
                onApiCall();
                toastMessage(formatMessage({ id: "deleted-message" }, { title: formatMessage({ id: 'property' }) }));
            })
            .catch((err) => {
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
            .finally(() => setDeleteLoading(false))
    }

    return (
        <PageLayout title={formatMessage({ id: "property" })}>
            <CustomTable
                columns={[...propertyColumns(formatMessage), columnAction]}
                tableData={userData.items}
                tableState={tableState}
                rowsCount={userData.meta?.totalItems}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />

        </PageLayout>
    )
}

export default Property;
