
import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIntl } from "react-intl";

import CustomTable from 'components/table';
import { TableEditButton, DeleteButton, TableButtonGroup } from 'components/button';
import { masterPaginationServices, tableInitialState, } from 'utils/constants';
import { getTableParams } from 'utils/global';
import { RootReducerState } from 'utils/types';
import { getMasterPaginationData } from 'redux/actions';
import PageLayout from 'layouts/PageLayout';
import Form from './Form';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { masterColumns } from 'utils/constants/tableColumn/column';

const Index: React.FC = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [createMode, setCreateMode] = useState(true);
    const [selectedRow, setSelectedRow] = useState(null);

    const { tableData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            tableData: masterPaginationReducer[masterPaginationServices.roadType].data,
            loading: masterPaginationReducer[masterPaginationServices.roadType].loading,
        }),
        shallowEqual
    );

    const { items , meta: { totalItems } } = tableData;

    useEffect(() => {
        onApiCall();
    }, [tableState]);

    function onApiCall() {
        const params = getTableParams(tableState);
        dispatch(getMasterPaginationData(masterPaginationServices.roadType, params));
    }

    const columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                return (
                    <TableButtonGroup>
                        <TableEditButton
                            tooltipLabel={`Edit ${formatMessage({ id: "road-type" })}}`}
                            onClick={() => handleRowClick(items[tableMeta.rowIndex])}
                        />
                        <DeleteButton
                            onDelete={() => onDeleteData(items[tableMeta.rowIndex])}
                        />
                    </TableButtonGroup>
                )
            }
        }
    }

    function handleRowClick(rowData: any) {
        setSelectedRow(rowData);
        setDrawer(true);
        setCreateMode(false);
    }

    function handleCreate() {
        setSelectedRow(null);
        setDrawer(true);
        setCreateMode(true);
    }

    function onDeleteData(data: any) {
        const params = { id: data.id };
        setDeleteLoading(true);
        services.deletePropertyTag(params)
            .then((res: any) => {
                setDeleteLoading(false);
                onApiCall();
                toastMessage(formatMessage({ id: "delete-message" }));
            })
            .catch((err) => {
                setDeleteLoading(false);
                toastMessage(err?.data?.message ? err?.data?.message : formatMessage({ id: "opps-error-message" }), 'error');
            })
    }

    return (
        <PageLayout title={formatMessage({ id: "road-type" })} onCreate={handleCreate}>
            <CustomTable
                columns={[...masterColumns(formatMessage), columnAction]}
                tableData={items}
                tableState={tableState}
                rowsCount={totalItems}
                setTableState={setTableState}
                loading={loading || deleteLoading}
            />

            {drawer && (
                <Form
                    refreshData={onApiCall}
                    open={drawer}
                    closeModal={() => setDrawer(false)}
                    formMode={createMode ? 'create' : 'update'}
                    selectedData={selectedRow}
                    headerText={createMode ? formatMessage({ id: "create-road-type" }) : formatMessage({ id: "update-road-type" })}
                />
            )}
        </PageLayout>
    )
}

export default Index;
