import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

// material
import { DatePicker } from '@mui/x-date-pickers';
import { Button, Grid, Stack, TextField } from '@mui/material';
import { AddBox, ArrowBack, Save } from '@mui/icons-material';

import { CustomCheckBoxGroup, CustomFileUpload, CustomRadioGroup, CustomSelect, CustomTextBox } from 'components/forms';
import { validationRule } from 'utils/global';
import { FileAcceptData, categoryTypeList, fileValidate, propertyBHKList, propertyExtraList, propertyPurposeList, propertyTypeList } from 'utils/constants';
import CardLayout from 'layouts/CardLayout';
import { RichTextEditor } from 'components/forms/RichTextEditor';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import OwnerForm from 'pages/owner/Form'

const GeneralForm = ({ uuid, formType, selectOptions, defaultData }: any) => {
    const { formatMessage } = useIntl();
    const [detail, setDetail] = useState('');
    const [currentCategory, setCurrentCategory] = useState('');
    const navigate = useNavigate();
    const { toastMessage } = useToastMessage();
    const [fileImage, setFileImage] = useState({
        thumbnail: '',
    })
    const [drawer, setDrawer] = useState({
        owners: false,
    });

    const [defaultOptions, setDefaultOptions] = useState<any>({
        owners: null,
        categories: null,
    });

    const propertyListOptions = propertyExtraList(formatMessage);
    const categoriesOption = selectOptions?.categories?.length > 0 ? defaultOptions.categories ? [...selectOptions?.categories, defaultOptions.categories] : selectOptions?.categories : [];
    const ownersOption = selectOptions?.owners?.length > 0 ? defaultOptions.owners ? [...selectOptions?.owners, defaultOptions.owners] : selectOptions?.owners : [];
    const addresssOption = selectOptions?.addresses?.length > 0 ? selectOptions.addresses : [];
    const propertyStatusOption = selectOptions?.propertyStatus?.length > 0 ? selectOptions.propertyStatus : [];
    const propertyFaceOption = selectOptions?.propertyFace?.length > 0 ? selectOptions.propertyFace : [];
    const areaUnitOption = selectOptions?.areaUnit?.length > 0 ? selectOptions.areaUnit : [];
    const roadTypeOption = selectOptions?.roadType?.length > 0 ? selectOptions.roadType : [];

    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, getValues, control, watch, reset } = useForm<any>({
        mode: 'all',
    });

    useEffect(() => {
        if (!uuid)
            reset({
                type: propertyPurposeList?.[0],
                status: propertyStatusOption?.[0]?.value,
                propertyType: propertyTypeList?.[0],
                // categoryType: categoryTypeList?.[0],
                areaCoveredUnit: areaUnitOption?.[0],
                builtAreaUnit: areaUnitOption?.[0],
                roadType: roadTypeOption?.[0],
                propertyFace: propertyFaceOption?.[0],
                bhkType: propertyBHKList?.[0],
                bedroomCount: 0,
                bathroomCount: 0,
                kitchenCount: 0,
                bedroomWithWashroomCount: 0,
                parkingSpaceCount: 0,
                livingroomCount: 0,
            })
    }, [selectOptions])

    function onSubmit(data: any) {
        data.thumbnail = data?.thumbnail?.[0];
        data.category = data?.category?.value;
        data.type = data?.type?.value;
        data.propertyFace = data?.propertyFace?.value;
        data.bhkType = data?.bhkType?.value;
        // data.status = data?.status?.value;
        data.location = data?.location?.value;
        data.propertyType = data?.propertyType?.value;
        data.categoryType = data?.categoryType?.value;
        data.roadType = data?.roadType?.value;
        data.areaCoveredUnit = data?.areaCoveredUnit?.value;
        data.builtAreaUnit = data?.builtAreaUnit?.value;
        data.owner = data?.owner?.value;
        data.detail = detail;
        data.isNegotiable = false;
        data.builtIn = data?.builtIn ? dayjs(data.builtIn).format('YYYY') : null;
        propertyListOptions.filter((p: any) => data.hasBoolean.includes(p.value) ? data[p.value] = true : data[p.value] = null);

        delete data.hasBoolean;

        if (formType === 'update') {
            data.uuid = uuid;
            delete data?.files;
            delete data?.amenities;
            delete data?.tags;
        }
        const apiFunc = formType === 'create' ? 'createProperty' : 'updateProperty';
        const message = formType === 'create' ? 'created-message' : 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'property' }) }));
                if (formType === 'create') {
                    navigate(`/admin/properties/update/amenities/${res.result.uuid}`)
                }
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
    }

    useEffect(() => {
        if (defaultData && uuid) {
            const category = defaultData.category?.uuid;
            const location = defaultData.location?.uuid;
            const owner = defaultData.owner?.uuid;
            const status = defaultData.status?.uuid;
            const propertyFace = defaultData.propertyFace?.uuid;
            const areaCoveredUnit = defaultData.areaCoveredUnit?.uuid;
            const builtAreaUnit = defaultData.builtAreaUnit?.uuid;
            const roadType = defaultData.roadType?.uuid;
            const hasBoolean = propertyListOptions.filter((p: any) => defaultData.hasOwnProperty(p.value) && defaultData[p.value] ? p.value : '').map((p: any) => p.value);

            reset({
                ...defaultData,
                thumbnail: null,
                builtIn: defaultData.builtIn ? dayjs(defaultData.builtIn) : null,
                type: propertyPurposeList.find((item: any) => item.value === defaultData.type),
                category: categoriesOption?.find((item: any) => item.value === category),
                owner: ownersOption?.find((item: any) => item.value === owner),
                areaCoveredUnit: areaUnitOption?.find((item: any) => item.value === areaCoveredUnit),
                builtAreaUnit: areaUnitOption?.find((item: any) => item.value === builtAreaUnit),
                roadType: roadTypeOption?.find((item: any) => item.value === roadType),
                propertyFace: propertyFaceOption.find((item: any) => item.value === propertyFace),
                bhkType: propertyBHKList.find((item: any) => item.value === defaultData.bhkType),
                propertyType: propertyTypeList.find((item: any) => item.value === defaultData.propertyType),
                // categoryType: categoryTypeList.find((item: any) => item.value === defaultData.categoryType),
                // status: propertyStatusOption.find((item: any) => item.value === status),
                status: status,
                location: addresssOption.find((item: any) => item.value === location),
                hasBoolean: hasBoolean
            });
            setCurrentCategory(defaultData?.category?.title)
            setDetail(defaultData.detail)
            setFileImage({ thumbnail: defaultData.thumbnail })
        }
    }, [defaultData, uuid, selectOptions]);

    const handleOwnerCallback = (response: any) => {
        const value = { value: response.uuid, label: response.fullName };
        setValue('owner', value);
        setDefaultOptions({ ...defaultOptions, owners: value })
    }

    useEffect(() => {
        if (currentCategory === 'Land') {
            // setValue('categoryType', categoryTypeList?.[0]);
            setValue('bhkType', propertyBHKList?.[0]);
            setValue('owner', "");
            setValue('bedroomCount', 0);
            setValue('propertyModa', 0);
            setValue('bathroomCount', 0);
            setValue('kitchenCount', 0);
            setValue('parkingSpaceCount', 0);
            setValue('bedroomWithWashroomCount', 0);
            setValue('hasBoolean', ['isVerified', 'isActive']);
        }
    }, [currentCategory])

    useEffect(() => {
        if (formType === 'create') {
            if (currentCategory == 'Land' || currentCategory == 'Apartment') {
                let title = '';
                if (watch('category')?.value) title += watch('category')?.label;
                if (watch('type')?.value) title += ' for ' + watch('type')?.value;
                if (watch('location')?.value) title += ' at ' + watch('location')?.label;
                setValue('title', title);
                setValue('propertyName', title);
            } else {
                let title = '';
                if (watch('propertyType')?.value) title += watch('propertyType')?.label + " ";
                if (watch('categoryType')?.value) title += watch('categoryType')?.label + " ";
                if (watch('category')?.value) title += watch('category')?.label;
                if (watch('type')?.value) title += ' for ' + watch('type')?.label;
                if (watch('location')?.value) title += ' at ' + watch('location')?.label;
                setValue('title', title);
                setValue('propertyName', title);
            }
        }
    }, [watch('category'), watch('categoryType'), watch('propertyType'), watch('type'), watch('bhkType'), watch('location')])

    return (
        <>
            {drawer.owners && (
                <OwnerForm
                    refreshData={handleOwnerCallback}
                    open={drawer.owners}
                    closeModal={() => setDrawer({ ...drawer, owners: false })}
                    formMode='create'
                    selectedData={null}
                    headerText={formatMessage({ id: "create-owner" })}
                />
            )}
            <CardLayout title={formatMessage({ id: "General Information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={categoriesOption}
                            label={formatMessage({ id: "categories" })}
                            name="category"
                            control={control}
                            error={errors.category}
                            rules={{ required: true }}
                            onChangeValue={d => setCurrentCategory(d.label)}
                        />
                    </Grid>
                    {/* {currentCategory !== 'Land' &&
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <CustomSelect
                                options={categoryTypeList}
                                label={formatMessage({ id: "category-type" })}
                                name="categoryType"
                                control={control}
                                error={errors.categoryType}
                                rules={{ required: true }}
                            />
                        </Grid>
                    } */}
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={propertyTypeList}
                            label={formatMessage({ id: "property-type" })}
                            name="propertyType"
                            control={control}
                            error={errors.propertyType}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={propertyPurposeList}
                            label={formatMessage({ id: "purpose" })}
                            name="type"
                            control={control}
                            error={errors.type}
                            rules={{ required: true }}
                        />
                    </Grid>
                    {currentCategory !== 'Land' &&
                        <Grid item lg={3} md={6} sm={12} xs={12}>
                            <CustomSelect
                                options={propertyBHKList}
                                label={formatMessage({ id: "BHK" })}
                                name="bhkType"
                                control={control}
                                error={errors.bhkType}
                            />
                        </Grid>
                    }
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={addresssOption}
                            label={formatMessage({ id: "location" })}
                            name="location"
                            control={control}
                            error={errors.location}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item
                        // lg={currentCategory === 'Land' ? 9 : 6} 
                        lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "title" })}
                            name="title"
                            control={control}
                            error={errors?.title}
                            rules={validationRule.textbox({ required: true })}
                        />
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "price" })}
                            name="price"
                            control={control}
                            error={errors?.price}
                            rules={validationRule.textbox({ required: true, type: "numberWithDecimal", maxLength: 20 })}
                        />
                    </Grid>
                    {/* <Grid item lg={9} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "property-name" })}
                            name="propertyName"
                            control={control}
                        />
                    </Grid> */}

                    {/* <Grid item lg={9} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address" })}
                            name="address"
                            control={control}
                        />
                    </Grid> */}
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "landmarks" })}
                            name="landmarks"
                            placeholder="Example : 2 km from buspark"
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={3} md={6} sm={12} xs={12}>
                        {/* <CustomSelect
                            options={propertyStatusOption}
                            label={formatMessage({ id: "status" })}
                            name="status"
                            control={control}
                            error={errors.status}
                            rules={{ required: true }}
                        /> */}
                        <CustomRadioGroup
                            control={control}
                            rules={{ required: true }}
                            label={formatMessage({ id: "status" })}
                            defaultValue={watch('status')}
                            name="status"
                            // radioGroupProps={propertyStatusOption} 
                            groupList={propertyStatusOption}
                        />
                    </Grid>
                </Grid>
            </CardLayout>
            {/* <CardLayout title={formatMessage({ id: "Price" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "price" })}
                            name="price"
                            control={control}
                            error={errors?.price}
                            rules={validationRule.textbox({ required: true, type: "numberWithDecimal", maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "final-price" })}
                            name="finalPrice"
                            control={control}
                            error={errors?.finalPrice}
                            rules={validationRule.textbox({ required: true, type: "numberWithDecimal", maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "sold-price" })}
                            name="soldPrice"
                            control={control}
                            error={errors?.soldPrice}
                            rules={validationRule.textbox({ required: true, type: "numberWithDecimal", maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomCheckBox
                            name="isNegotiable"
                            label={formatMessage({ id: "negotiable" })}
                            control={control}
                        />
                    </Grid>
                </Grid>
            </CardLayout> */}

            <CardLayout title={formatMessage({ id: "property-information" })}>
                <Grid container spacing={2}>
                    {currentCategory !== 'Land' &&
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={10}>
                                    <CustomSelect
                                        options={ownersOption}
                                        label={formatMessage({ id: "owner" })}
                                        name="owner"
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={2} sx={{ padding: 0 }}>
                                    <AddBox
                                        sx={{ fontSize: 50 }}
                                        onClick={() => setDrawer({ ...drawer, owners: true })}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomSelect
                            options={propertyFaceOption}
                            label={formatMessage({ id: "property-face" })}
                            name="propertyFace"
                            control={control}
                            error={errors.propertyFace}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <CustomTextBox
                                    label={formatMessage({ id: "access-road" })}
                                    name="accessRoad"
                                    control={control}
                                    error={errors?.accessRoad}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomSelect
                                    options={roadTypeOption}
                                    label={formatMessage({ id: "road-type" })}
                                    name="roadType"
                                    control={control}
                                    error={errors.roadType}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <CustomTextBox
                                    label={formatMessage({ id: "area-covered" })}
                                    name="areaCovered"
                                    control={control}
                                    error={errors?.areaCovered}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomSelect
                                    options={areaUnitOption}
                                    label={formatMessage({ id: "unit" })}
                                    name="areaCoveredUnit"
                                    control={control}
                                    error={errors.areaCoveredUnit}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <CustomTextBox
                                    label={formatMessage({ id: "built-area" })}
                                    name="builtArea"
                                    control={control}
                                    error={errors?.builtArea}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CustomSelect
                                    options={areaUnitOption}
                                    label={formatMessage({ id: "unit" })}
                                    name="builtAreaUnit"
                                    control={control}
                                    error={errors.builtAreaUnit}
                                    rules={validationRule.textbox({ required: true })}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "moda" })}
                            name="propertyModa"
                            control={control}
                            error={errors?.propertyModa}
                        />
                    </Grid>
                    {currentCategory !== 'Land' && <>
                        <Grid item lg={4} md={6} sm={12} xs={12}>
                            <DatePicker
                                views={['year']}
                                label="Year only"
                                value={watch('builtIn')}
                                onChange={(newValue) => {
                                    setValue('builtIn', newValue);
                                }}
                                renderInput={(params: any) => <TextField fullWidth {...params} helperText={null} />}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={6}>
                            <CustomTextBox
                                label={formatMessage({ id: "bedroom-count" })}
                                name="bedroomCount"
                                control={control}
                                error={errors?.bedroomCount}
                                rules={validationRule.textbox({ type: "number" })}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={6}>
                            <CustomTextBox
                                label={formatMessage({ id: "bathroom-count" })}
                                name="bathroomCount"
                                control={control}
                                error={errors?.bathroomCount}
                                rules={validationRule.textbox({ type: "number" })}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={6}>
                            <CustomTextBox
                                label={formatMessage({ id: "kitchen-count" })}
                                name="kitchenCount"
                                control={control}
                                error={errors?.kitchenCount}
                                rules={validationRule.textbox({ type: "number" })}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={6}>
                            <CustomTextBox
                                label={formatMessage({ id: "bedroom-with-washroom-count" })}
                                name="bedroomWithWashroomCount"
                                control={control}
                                error={errors?.bedroomWithWashroomCount}
                                rules={validationRule.textbox({ required: true, type: "number" })}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={6}>
                            <CustomTextBox
                                label={formatMessage({ id: "living-room-count" })}
                                name="livingroomCount"
                                control={control}
                                error={errors?.livingroomCount}
                                rules={validationRule.textbox({ required: true, type: "number" })}
                            />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={6}>
                            <CustomTextBox
                                label={formatMessage({ id: "parking-space-count" })}
                                name="parkingSpaceCount"
                                control={control}
                                error={errors?.parkingSpaceCount}
                                rules={validationRule.textbox({ required: true, type: "number" })}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomCheckBoxGroup
                                name='hasBoolean'
                                control={control}
                                groupList={propertyListOptions}
                                gridProps={{ item: true, lg: 3, xs: 12 }}
                            />
                        </Grid>
                    </>}
                </Grid>
            </CardLayout>

            <CardLayout title={formatMessage({ id: "property-front-image" })}>
                <Grid container spacing={2}>
                    {/* <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "thumbnail-alt" })}
                            name="thumbnailAlt"
                            control={control}
                            error={errors?.thumbnailAlt}
                        />
                    </Grid> */}
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            label="Image"
                            error={errors?.thumbnail}
                            {...register('thumbnail', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                            })}
                            defaultFileUrl={fileImage.thumbnail}
                            defaultFileName={''}
                            onClear={() => {
                                setValue(`thumbnail`, null);
                                clearErrors(`thumbnail`);
                            }}
                            onChange={(file: any) => setValue('thumbnail', file)}
                        />
                    </Grid>
                </Grid>
            </CardLayout>

            <CardLayout title={formatMessage({ id: "detail" })}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RichTextEditor
                            value={detail}
                            setTemplate={(value: any) => setDetail(value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "map-address" })}
                            name="map"
                            control={control}
                        />
                    </Grid>
                    {/* <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "longitude" })}
                            name="longitude"
                            control={control}
                        />
                    </Grid>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "latitude" })}
                            name="latitude"
                            control={control}
                        />
                    </Grid> */}
                </Grid>
            </CardLayout>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" my={5}>
                        <Button variant="outlined" type='button' onClick={() => navigate(-1)} startIcon={<ArrowBack />}>
                            Cancel
                        </Button>
                        <Button variant="contained" type='submit' onClick={handleSubmit(onSubmit)} startIcon={<Save />} style={{ marginLeft: 15 }}>
                            {formType === 'create' ? 'Submit' : 'Update'}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

export default GeneralForm;