import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Button, Card, Grid, Stack } from '@mui/material';
import { Save } from '@mui/icons-material';

import { CustomFileUpload, CustomTextBox } from 'components/forms';
import { HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import SectionLayout from 'layouts/SectionLayout';
import { services } from 'redux/services';
import { FileAcceptData, fileValidate, images } from 'utils/constants';

const Profile: React.FC<any> = ({ defaultData }: any) => {
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm({ mode: 'all' }) as any;
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [photoData, setPhotoData] = useState();

    function onSubmit(data: any) {
        setLoading(true);
        data.uuid = defaultData.uuid;
        data.photo = data?.photo?.[0];
        const apiFunc = 'updateAdminProfile';
        const message = 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'user' }) }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            }).finally(() => setLoading(false))
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (defaultData) {
            reset({
                firstName: defaultData.firstName,
                middleName: defaultData.middleName,
                lastName: defaultData.lastName,
                userName: defaultData.userName,
                email: defaultData.email,
                phone: defaultData.phone,
            });
            setPhotoData(defaultData.photo);
        }
    }, [defaultData]);

    return (
        <SectionLayout title={formatMessage({ id: "profile-information" })}>
            {loading && <HoverLoader />}
            <Grid container spacing={2}>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "username" })}
                        name="userName"
                        disabled
                        control={control}
                        error={errors?.userName}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "first-name" })}
                        name="firstName"
                        control={control}
                        error={errors?.firstName}
                        rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "last-name" })}
                        name="lastName"
                        control={control}
                        error={errors?.lastName}
                        rules={validationRule.textbox({ required: true, type: "textWithSpace" })}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "phone" })}
                        name="phone"
                        control={control}
                        error={errors?.phone}
                        rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomFileUpload
                        accept={FileAcceptData.IMAGES}
                        {...register('photo', {
                            validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                        })}
                        label="Photo"
                        defaultFileUrl={photoData}
                        defaultFileName={''}
                        onClear={() => {
                            setValue(`photo`, null);
                            clearErrors(`photo`);
                        }}
                        onChange={(file:any) => setValue('photo' , file)}
                    />
                </Grid>
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" my={5}>
                <Button variant="contained" type='submit' onClick={handleSubmit(onSubmit)} startIcon={<Save />}>
                    Update Profile
                </Button>
            </Stack>
        </SectionLayout>
    )
}

export default Profile;