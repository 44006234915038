import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

// material
import { Button, Grid } from '@mui/material';
import { Add, Save } from '@mui/icons-material';

import { CustomFileUpload } from 'components/forms';
import CardLayout from 'layouts/CardLayout';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { FileAcceptData, fileValidate } from 'utils/constants';
import { CustomDeleteButton } from 'components/button';

const FileUploadForm = ({ uuid, defaultData }: any) => {
    let myRef = useRef<any>(null);
    const { formatMessage } = useIntl();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, watch } = useForm({ mode: 'all' });
    const { toastMessage } = useToastMessage();
    const [fileArray, setFileArray] = useState([]);
    const [wasTriggered, setWasTriggered] = useState(false)

    function onSubmit(data: any) {
        setWasTriggered(false);
        if (data.files?.length > 0) {
            data.uuid = uuid;
            data.files = data?.files?.[0];
            const apiFunc = 'uploadPopertyFile';
            const message = 'updated-message';
            services[apiFunc](data)
                .then((res: any) => {
                    setValue(`files`, null);
                    clearErrors(`files`);
                    setFileArray(res?.result);
                    setWasTriggered(true);
                    toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'property' }) }));
                })
                .catch((err: any) => {
                    toastMessage(err?.data?.message || 'Something Wrong', 'error');
                })
        } else {
            toastMessage('Please upload file', 'error');
        }
    }

    function onDelete(fileUuid: string) {
        const apiFunc = 'deletePopertyFile';
        const message = 'deleted-message';
        services[apiFunc]({
            fileUuid: fileUuid,
            uuid: uuid
        }).then((res: any) => {
            setFileArray(res?.result);
            toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'file' }) }));
        })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
    }

    useEffect(() => {
        if (defaultData && uuid) {
            setFileArray(defaultData?.files)
        }
    }, [defaultData, uuid]);

    return (
        <>
            <CardLayout showHeader={false} title={formatMessage({ id: "property-information" })}>
                <Grid container spacing={2}>
                    <Grid item lg={4} md={6} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            label="Image"
                            error={errors?.files}
                            {...register('files', {
                                validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true
                            })}
                            onClear={() => {
                                setValue(`files`, null);
                                clearErrors(`files`);
                            }}
                            wasTriggered={wasTriggered}
                        onChange={(file:any) => setValue('files' , file)}
                        />
                    </Grid>
                    <Grid item lg={2} md={6} sm={12} xs={12}>
                        <Button variant="contained" type='submit' onClick={handleSubmit(onSubmit)} startIcon={<Save />}  fullWidth>
                            Upload Image
                        </Button>
                    </Grid>

                </Grid>
                <Grid container spacing={3} sx={{ marginTop: 2 }}>
                    {fileArray?.length > 0 && fileArray?.map((item: any, index: number) =>
                        <Grid item lg={3} md={6} sm={6} xs={6} key={index}>
                            <img
                                src={item.url}
                                style={{ marginTop: 10, marginBottom: 10, height: 150, width: '100%' }}
                            />
                            <CustomDeleteButton
                                variant="outlined"
                                label="Remove"
                                fullWidth
                                onDelete={() => onDelete(item.uuid)}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardLayout>
        </>
    )
}

export default FileUploadForm;