import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';

// material
import { Button, Grid, Stack, Typography } from '@mui/material';
import { Save } from '@mui/icons-material';

import { CustomCheckBoxGroup } from 'components/forms';
import CardLayout from 'layouts/CardLayout';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';

const AmenitiesForm = ({ uuid, defaultData, selectOptions }: any) => {
    const { formatMessage } = useIntl();
    const [amenitiesList, setAmenitiesList] = useState([]);
    const { handleSubmit, formState: { errors }, setValue, control, watch, reset } = useForm({ mode: 'all' });
    const { toastMessage } = useToastMessage();

    useEffect(() => {
        if (selectOptions?.length > 0) {
            setAmenitiesList(selectOptions.map((item: any) => ({
                label:
                    <div style={{ textAlign: 'center', marginBottom: 30 }}>
                        <img src={item.image} key={item.id} className="profile-img" style={{ marginRight: "5px", padding: 10, width: 60, height: 60 }} />
                        <Typography variant="body2">
                            {item.title}
                        </Typography>
                    </div>,
                value: item.uuid
            })))
        }

    }, [selectOptions]);

    function onSubmit(data: any) {
        data.uuid = uuid;
        const apiFunc = 'updatePropertyFeature';
        const message = 'updated-message';
        services[apiFunc](data)
            .then((res: any) => {
                toastMessage(formatMessage({ id: message }, { title: formatMessage({ id: 'property' }) }));
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
    }

    useEffect(() => {
        if (defaultData && uuid) {
            reset({
                amenities: selectOptions?.filter((item: any) => defaultData?.amenities?.map((l: any) => l.uuid)?.includes(item.uuid)).map((item: any) => item.uuid),
                tags: selectOptions?.tags?.filter((item: any) => defaultData?.tags?.map((s: any) => s.uuid)?.includes(item.value)),
            });
        }
    }, [defaultData, uuid]);

    return (
        <>
            <CardLayout showHeader={false} title={formatMessage({ id: "property-information" })}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomCheckBoxGroup
                            name='amenities'
                            control={control}
                            groupList={amenitiesList}
                            gridProps={{ item: true, lg: 3, md: 4, xs: 6 }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item lg={9} md={6} sm={12} xs={12}>
                            </Grid>
                            <Grid item lg={3} md={6} sm={12} xs={12}>
                                <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                    <Button variant="contained" type='submit' onClick={handleSubmit(onSubmit)} startIcon={<Save />} fullWidth>
                                        Update
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardLayout>
        </>
    )
}

export default AmenitiesForm;