import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// @ts-ignore
import styled from "@emotion/styled";
import { FormErrorMessage } from "./FormErrorMessage";

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];


const EditorContainerStyle = styled("div")(({ theme }: any) => ({
  overflow: "hidden",
  borderBottom: `1px solid ${theme.palette.action.hover}`,
}));

interface Props {
  onChange: (value: string) => void;
  defaultValue?: string;
  error?: any;
  formLabel?: string;
  height?: number;
  name: string;
  placeholder?: string;
}
const CustomTextEditor = ({
  name,
  defaultValue,
  error,
  formLabel,
  placeholder,
  onChange
}: Props) => {
  // const reactQuillRef :any = useRef<HTMLInputElement>(null);
  const [state, setState] = useState("");
  
  const handleChange = (value: any) => {
    setState(value);
    onChange(value);
  };

  useEffect(() => {
    if (defaultValue) setState(defaultValue);
  }, [defaultValue]);

  // const undoChange = () => {
  //     let myEditor = reactQuillRef.current?.getEditor();
  //     return myEditor?.history?.undo();
  // }

  // const redoChange = () => {
  //     let myEditor = reactQuillRef.current?.getEditor();
  //     return myEditor?.history?.redo();
  // }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          // ['undo','redo'],
          [{ list: "ordered" }, { list: "bullet" }],

          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          ["link", "image", "video"],
          ["clean"],
        ],
        // handlers: {
        //   undo: undoChange,
        // redo: redoChange
        // },
        history: {
          delay: 500,
          maxStack: 100,
          userOnly: true,
        },
      },
    }),
    []
  );

  return (
    <EditorContainerStyle>
      {formLabel && (
        <span className="text-13 font-medium" style={{ marginBottom: 8 }}>
          {formLabel}
        </span>
      )}
      <ReactQuill
        id={name}
        theme="snow"
        onChange={handleChange}
        placeholder={placeholder}
        modules={modules}
        value={state || ""}
        formats={formats}
        preserveWhitespace
      // ref={reactQuillRef}
      />
      <FormErrorMessage error={error} />
    </EditorContainerStyle>
  );
};

CustomTextEditor.defaultProps = {
  defaultValue: "<p></br></p>",
};

export default CustomTextEditor;
