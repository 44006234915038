import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Check } from "@mui/icons-material";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import Logo from "./dashboard/Logo";
import useResponsive from "hooks/useResponsive";
import { images } from "utils/constants";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    height: "100vh",
    backgroundImage: `url(${images.rectangle1})`,
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 750,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.shadows,
  color: "#fff",
  backgroundImage: `url(${images.rectangle2})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  padding: theme.spacing(0, 14),
}));


export default function MainLayout() {
  const mdUp = useResponsive("up", "md");
  return (
    <StyledRoot>
      {mdUp && (
        <StyledSection>
          <Logo
            sx={{
              position: "fixed",
              top: { xs: 16, sm: 24, md: 100 },
              left: { xs: 16, sm: 24, md: 100 },
              height: 100,
            }}
          />
          <Typography variant="h6" sx={{ px: 2, mt: 10, mb: 2 }}>
            Things you can do with <Typography variant="inherit" display="inline" fontSize={24}>Gharawas</Typography> Account.
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ background: "#6327e4" }}>
                      <Check />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Create an account on Gharawas real estate site." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ background: "#6327e4" }}>
                      <Check />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Add your properties with appealing descriptions and images." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ background: "#6327e4" }}>
                      <Check />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Gain access to an unlimited number of potential buyers" />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{ background: "#6327e4" }}>
                      <Check />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Add detailed information about your property, including multiple photos, to your listing" />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </StyledSection>
      )}
        <Outlet />
    </StyledRoot>
  );
}
