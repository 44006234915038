import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
// material

import { HoverLoader } from 'components';
import { useCheckRole, useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { Container } from '@mui/system';
import Profile from './Profile';
import Account from './Account';
import Info from './Info';

const Setting: React.FC = () => {
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [defaultData, setDefaultData] = useState<any>(null);
    const { isAdmin, isSuperAdmin } = useCheckRole()
    
    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        // setLoading(true);
        if(isSuperAdmin){
            services.getSetting()
            .then((res: any) => {
                const response = res.result;
                setDefaultData(response);
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
            .finally(() => setLoading(false))
        }else if(isAdmin){
            services.profile()
            .then((res: any) => {
                const response = res.result;
                setDefaultData(response);
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
            .finally(() => setLoading(false))
        }
       
    }, []);

    return (
        <Container maxWidth="xl" style={{ maxWidth: '100%' }}>
            {loading && <HoverLoader />}
            {isAdmin && <>
                <Account defaultData={defaultData} />
                <Profile defaultData={defaultData} />
            </>}
            {isSuperAdmin && <Info defaultData={defaultData} />}

        </Container>
    )
}

export default Setting;