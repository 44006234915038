import { Address } from 'pages';
import Dashboard from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Category, Forum, House, Image, PersonAdd, Settings, AccountBox, AnnouncementOutlined, Pages, ContactMail, Book, Tag } from '@mui/icons-material';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { roleData } from './role';

const SidebarMenu = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: <Dashboard />,
    role: [roleData.superadmin, roleData.admin, roleData.agent, roleData.blog]
  },
  {
    title: 'Account',
    path: '/admin/accounts',
    icon: <AccountBoxIcon />,
    role: [roleData.superadmin]
  },
  {
    title: 'Banner',
    path: '/admin/banners',
    icon: <Image />,
    role: [roleData.superadmin]
  },
  {
    title: 'Property',
    path: '/admin/properties',
    icon: <House />,
    role: [roleData.admin, roleData.agent]
  },
  {
    title: 'Blog',
    path: '/admin/blogs',
    icon: <Forum />,
    role: [roleData.superadmin, roleData.admin, roleData.agent, roleData.blog]
  },
  {
    title: 'Pages',
    path: '/admin/pages',
    icon: <Pages />,
    role: [roleData.superadmin]
  },
  {
    title: 'Customers',
    path: '/admin/customers',
    icon: <AccountBox />,
    role: [roleData.superadmin]
  },
  {
    title: 'Partner',
    path: '/admin/partner',
    icon: <SupervisedUserCircleIcon />,
    role: [roleData.superadmin]
  },
  {
    title: 'Advertisement',
    path: '/admin/advertisement',
    icon: <AnnouncementOutlined />,
    role: [roleData.superadmin]
  },
  {
    title: 'Owner',
    path: '/admin/owners',
    icon: <PersonAdd />,
    role: [roleData.admin,roleData.agent]
  },
  {
    title: 'Users',
    path: '/admin/account-users',
    icon: <AccountCircleIcon />,
    role: [roleData.superadmin, roleData.admin]
  },
  {
    title: 'Inquiry',
    path: '/admin/inquiry',
    icon: <ContactMail />,
    role: [roleData.superadmin, roleData.admin, roleData.agent]
  },
  {
    title: 'Master',
    path: '#',
    icon: <Settings />,
    role: [roleData.superadmin],
    children: [
      {
        title: 'Amenities',
        path: '/admin/amenities',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },
      {
        title: 'Tags',
        path: '/admin/property-tags',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },
      {
        title: 'Category',
        path: '/admin/category',
        icon: <Category />,
        role: [roleData.superadmin]
      },
      {
        title: 'Address',
        path: '/admin/address',
        icon: <Address />,
        role: [roleData.superadmin]
      },
      {
        title: 'Property Face',
        path: '/admin/property-face',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },
      {
        title: 'Property Status',
        path: '/admin/property-status',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },
      {
        title: 'Road Type',
        path: '/admin/road-type',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },
      {
        title: 'Area Unit',
        path: '/admin/area-units',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },
      {
        title: 'Blog Tags',
        path: '/admin/blog-tags',
        icon: <Tag />,
        role: [roleData.superadmin]
      },
      {
        title: 'Blog Category',
        path: '/admin/blog-categories',
        icon: <Book />,
        role: [roleData.superadmin]
      },
      {
        title: 'Role',
        path: '/admin/roles',
        icon: <GroupIcon />,
        role: [roleData.superadmin]
      },

    ]
  }
];

export default SidebarMenu;
