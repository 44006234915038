import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
// material
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Save } from "@mui/icons-material";

import {
    CustomCheckBox,
    CustomFileUpload,
    CustomTextArea,
    CustomTextBox,
} from "components/forms";
import { HoverLoader } from "components";
import { validationRule } from "utils/global";
import { useToastMessage } from "hooks";
import SectionLayout from "layouts/SectionLayout";
import { services } from "redux/services";
import { FileAcceptData, fileValidate, images } from "utils/constants";
import { CreateButton, DeleteButton } from "components/button";

const Info: React.FC<any> = ({ defaultData }: any) => {
    const { toastMessage } = useToastMessage();
    const {
        handleSubmit,
        formState: { errors },
        register,
        clearErrors,
        setValue,
        control,
        watch,
        reset,
    } = useForm({
        mode: "all",
        defaultValues: {
            emailInfo: [{ label: "", value: "" }],
            socialMedia: [{ label: "", value: "" }],
        },
    }) as any;
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [primaryLogoData, setPrimaryLogoData] = useState(null);
    const [secondayLogoData, setSecondaryLogoData] = useState(null);
    const [faviconData, setFaviconData] = useState(null);
    const {
        fields: efields,
        append: eappend,
        remove: eremove,
    } = useFieldArray({
        control,
        name: "emailInfo",
    });
    const {
        fields: sfields,
        append: sappend,
        remove: sremove,
    } = useFieldArray({
        control,
        name: "socialMedia",
    });

    function onSubmit(data: any) {
        setLoading(true);
        data.primaryLogo = data?.primaryLogo?.[0];
        data.secondaryLogo = data?.secondaryLogo?.[0];
        data.favicon = data?.favicon?.[0];
        data.emailInfo = JSON.stringify(data.emailInfo);
        data.socialMedia = JSON.stringify(data.socialMedia);
        const apiFunc = "createOrUpdateSetting";
        const message = "updated-message";
        services[apiFunc](data)
            .then((res: any) => {
                toastMessage(
                    formatMessage(
                        { id: message },
                        { title: formatMessage({ id: "account" }) }
                    )
                );
            })
            .catch((err: any) => {
                toastMessage(err?.data?.message || 'Something Wrong', "error");
            })
            .finally(() => setLoading(false));
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        // setLoading(true);
        if (defaultData) {
            reset({
                ...defaultData,
                primaryLogo: null,
                secondaryLogo: null,
                favicon: null,
                emailInfo: defaultData?.emailInfo?.length > 0 ? defaultData?.emailInfo : [{ label: "", value: "" }],
                socialMedia: defaultData?.socialMedia?.length > 0 ? defaultData?.socialMedia :[{ label: "", value: "" }],
            });
            setPrimaryLogoData(defaultData.primaryLogo);
            setSecondaryLogoData(defaultData.secondaryLogo);
            setFaviconData(defaultData.favicon);
        }
    }, [defaultData]);

    return (
        <>
            <SectionLayout title={formatMessage({ id: "system-information" })}>
                {loading && <HoverLoader />}
                <Grid container spacing={2}>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "name" })}
                            name="companyName"
                            control={control}
                            error={errors?.companyName}
                            rules={validationRule.textbox({
                                required: true,
                                type: "textWithSpace",
                            })}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "email" })}
                            name="companyEmail"
                            control={control}
                            error={errors?.companyEmail}
                            rules={validationRule.textbox({ type: "email", required: true })}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "phone" })}
                            name="companyPhone"
                            control={control}
                            error={errors?.companyPhone}
                            rules={validationRule.textbox({
                                type: "contact",
                                maxLength: 20,
                                required: true,
                            })}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "primary-phone" })}
                            name="primaryPhoneNumber"
                            control={control}
                            error={errors?.primaryPhoneNumber}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "secondary-phone" })}
                            name="secondaryPhoneNumber"
                            control={control}
                            error={errors?.secondaryPhoneNumber}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "primary-mobile" })}
                            name="primaryMobileNumber"
                            control={control}
                            error={errors?.primaryMobileNumber}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "secondary-mobile" })}
                            name="secondaryMobileNumber"
                            control={control}
                            error={errors?.secondaryMobileNumber}
                            rules={validationRule.textbox({ type: "contact", maxLength: 20 })}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "address" })}
                            name="companyAddress"
                            control={control}
                            error={errors?.companyAddress}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "footer-text" })}
                            name="footerText"
                            control={control}
                            error={errors?.footerText}
                        />
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "copyright-text" })}
                            name="copyRightText"
                            control={control}
                            error={errors?.copyRightText}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "map" })}
                            name="companyMap"
                            control={control}
                            error={errors?.companyMap}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextArea
                            formLabel={formatMessage({ id: "detail" })}
                            name="companyDetail"
                            control={control}
                            minRows={4}
                        />
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            {...register("primaryLogo", {
                                validate: (value: any) =>
                                    value?.length > 0 ? fileValidate(value?.[0]) : true,
                            })}
                            label="Primary Logo"
                            defaultFileUrl={primaryLogoData}
                            defaultFileName={""}
                            onClear={() => {
                                setValue(`primaryLogo`, null);
                                clearErrors(`primaryLogo`);
                            }}
                            onChange={(file: any) => setValue("primaryLogo", file)}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            {...register("secondaryLogo", {
                                validate: (value: any) =>
                                    value?.length > 0 ? fileValidate(value?.[0]) : true,
                            })}
                            label="Secondary Logo"
                            defaultFileUrl={secondayLogoData}
                            defaultFileName={""}
                            onClear={() => {
                                setValue(`secondaryLogo`, null);
                                clearErrors(`secondaryLogo`);
                            }}
                            onChange={(file: any) => setValue("secondaryLogo", file)}
                        />
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <CustomFileUpload
                            accept={FileAcceptData.IMAGES}
                            {...register("favicon", {
                                validate: (value: any) =>
                                    value?.length > 0 ? fileValidate(value?.[0]) : true,
                            })}
                            label="Favicon"
                            defaultFileUrl={faviconData}
                            defaultFileName={""}
                            onClear={() => {
                                setValue(`favicon`, null);
                                clearErrors(`favicon`);
                            }}
                            onChange={(file: any) => setValue("favicon", file)}
                        />
                    </Grid>
                </Grid>
            </SectionLayout>

            <SectionLayout title={formatMessage({ id: "email-socialmedia-info" })}>
                <Grid container spacing={2}>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {efields.map((field, index) => (
                            <Grid container spacing={1} mb={2} key={field.id} alignItems="center">
                                <Grid item lg={5} md={5} sm={12} xs={12}>
                                    <CustomTextBox
                                        label="Email Name"
                                        name={`emailInfo.${index}.label`}
                                        placeholder="Support,Marketing ...."
                                        control={control}
                                        error={errors?.emailInfo?.[`${index}.label`]}
                                    />
                                </Grid>
                                <Grid item lg={5} md={5} sm={12} xs={12}>
                                    <CustomTextBox
                                        label="Email"
                                        name={`emailInfo.${index}.value`}
                                        placeholder="support@xyz.com...."
                                        control={control}
                                        error={errors?.emailInfo?.[`${index}.value`]}
                                    />
                                </Grid>
                                <Grid item lg={1} md={1} sm={6} xs={6}>
                                    {efields.length !== 1 && (
                                        <DeleteButton
                                            showConfirmation={false}
                                            onDelete={() => eremove(index)}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={1} md={1} sm={6} xs={6}>
                                    {efields.length - 1 === index && (
                                        <CreateButton onClick={() => eappend({ label: "", value: "" })} />
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {sfields.map((field, index) => (
                            <Grid container spacing={1} mb={2} key={field.id} alignItems="center">
                                <Grid item lg={5} md={5} sm={12} xs={12}>
                                    <CustomTextBox
                                        label="Social Media Name"
                                        name={`socialMedia.${index}.label`}
                                        placeholder="Facebook, ...."
                                        control={control}
                                        error={errors?.socialMedia?.[`${index}.label`]}
                                    />
                                </Grid>
                                <Grid item lg={5} md={5} sm={12} xs={12}>
                                    <CustomTextBox
                                        label="Social Medial Url"
                                        name={`socialMedia.${index}.value`}
                                        placeholder="facebook url...."
                                        control={control}
                                        error={errors?.socialMedia?.[`${index}.value`]}
                                    />
                                </Grid>
                                <Grid item lg={1} md={1} sm={6} xs={6}>
                                    {sfields.length !== 1 && (
                                        <DeleteButton
                                            showConfirmation={false}
                                            onDelete={() => sremove(index)}
                                        />
                                    )}
                                </Grid>
                                <Grid item lg={1} md={1} sm={6} xs={6}>
                                    {sfields.length - 1 === index && (
                                        <CreateButton onClick={() => sappend({ label: "", value: "" })} />
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </SectionLayout>

            <SectionLayout title={formatMessage({ id: "seo-information" })}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "meta-title" })}
                            name="metaTitle"
                            control={control}
                            error={errors?.metaTitle}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "meta-keyword" })}
                            name="metaKeyword"
                            control={control}
                            error={errors?.metaKeyword}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextBox
                            label={formatMessage({ id: "meta-description" })}
                            name="metaDescription"
                            control={control}
                            error={errors?.metaDescription}
                        />
                    </Grid>
                </Grid>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    my={5}
                >
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        startIcon={<Save />}
                    >
                        Update Account
                    </Button>
                </Stack>
            </SectionLayout>
        </>
    );
};

export default Info;
