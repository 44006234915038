import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
// material
import { Grid } from '@mui/material';

import { CustomCheckBox, CustomSelect, CustomTextBox } from 'components/forms';
import { CustomDrawer, HoverLoader } from 'components';
import { validationRule } from 'utils/global';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { useSelector, shallowEqual } from 'react-redux';
import { masterLookupServices } from 'utils/constants';
import { RootReducerState } from 'utils/types';

const groupDistrictByProvince = (data: any) => {
    return data?.map((item: any) => ({
        label: item.province.name,
        districtName: item.name,
        value: item.id,
        options: item.municipality.map((item: any) => ({ label: item.name, value: item.id }))
    }));
};

interface Props {
    open: boolean;
    closeModal: () => void;
    formMode: 'create' | 'update';
    selectedData: any;
    headerText: string;
    refreshData: () => void;
}

const Form = (props: Props) => {
    const { closeModal, open, formMode, selectedData, headerText, refreshData } = props;
    const { toastMessage } = useToastMessage();
    const { handleSubmit, formState: { errors }, register, clearErrors, setValue, control, watch, reset } = useForm<any>({ mode: 'all', defaultValues: { isActive: true } });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [municipalityOption, setMunicipalityOption] = useState([])

    const { getAddressLookup } = useSelector(
        ({ masterLookupReducer }: RootReducerState) => {
            return ({
                getAddressLookup: masterLookupReducer[masterLookupServices.getAddressLookup].data
            })
        },
        shallowEqual
    );

    const districtList = getAddressLookup?.district?.length > 0 ? groupDistrictByProvince(getAddressLookup.district) : [];

    function onSubmit(data: any) {
        setLoading(true);
        const apiFunc = formMode === 'create' ? 'createAddress' : 'updateAddress';
        const message = formMode === 'create' ? 'created-message' : 'updated-message';
        data.municipalityId = data.municipalityId.value;
        delete data.district;
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { name: "address" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err?.data?.message || 'Something Wrong', 'error');
            })
    }

    //RESET DEFAULTVALUE IN UPDATE MODE
    useEffect(() => {
        if (selectedData) {
            reset({
                uuid: selectedData.uuid,
                name: selectedData.name,
                wardNo: selectedData.wardNo,
                isActive: selectedData.isActive,
            })
            const districtFind = districtList.find((item: any) => +item.value === +selectedData?.municipality?.district?.id);
            if (selectedData?.municipality && districtFind) {
                setValue('district', districtFind);
                setValue('municipalityId', districtFind?.options.find((item: any) => +item.value === +selectedData.municipality.id));
            }
        }
    }, [formMode === 'update']);

    useEffect(() => {
        if (watch('district')) {
            setMunicipalityOption(watch('district')?.options);
        } else {
            setMunicipalityOption([]);
            setValue('municipalityId', null)
        }
    }, [watch('district')])

    return (
        <CustomDrawer
            formMode={formMode}
            open={open}
            onCancel={closeModal}
            onSubmit={handleSubmit(onSubmit)}
            title={headerText}
        >
            <Grid container spacing={2}>
                {loading && <HoverLoader />}

                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "name" })}
                        name="name"
                        control={control}
                        error={errors?.name}
                        rules={validationRule.textbox({ required: true })}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomSelect
                        options={districtList}
                        groupBy={(option: any) => option.label}
                        getOptionLabel={(option: any) => option.districtName}
                        label={formatMessage({ id: "District" })}
                        name="district"
                        control={control}
                        error={errors.district}
                        rules={{ required: true }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomSelect
                        options={municipalityOption}
                        label={formatMessage({ id: "municipality" })}
                        name="municipalityId"
                        control={control}
                        error={errors.municipalityId}
                        rules={{ required: true }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CustomTextBox
                        label={formatMessage({ id: "ward-no" })}
                        name="wardNo"
                        control={control}
                        error={errors?.wardNo}
                        rules={validationRule.textbox({ type: 'number', required: true })}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} xs={12}>
                    <CustomCheckBox
                        name="isActive"
                        label={formatMessage({ id: "is-active" })}
                        control={control}
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    )
}

export default Form;
