import { useEffect, useState } from 'react';
import Axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { styled } from '@mui/material/styles';
import { Container, Stack, Typography, InputAdornment, Tooltip, Card, Link as MuiLink } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MuiOtpInput } from 'mui-one-time-password-input'
import InfoIcon from '@mui/icons-material/Info';
import { useQuery, useToastMessage } from 'hooks';
import { RootReducerState } from 'utils/types';
import { OtpConstant, otpLength } from 'utils/constants';
import { API_ENDPOINTS } from 'redux/apiEndPoints';
import { CustomTextBox } from 'components/forms';
import { useIntl } from 'react-intl';
import { validationRule } from 'utils/global';
import { HoverLoader } from 'components';
import { Helmet } from 'react-helmet-async';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0)
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 550,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.shadows,
  backgroundColor: theme.palette.background.default,
}));

export default function VerifyOtp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { toastMessage } = useToastMessage();

  const query = useQuery();
  const email = query.get("email");
  const otpType = query.get("otpType") as any;

  const [delay, setDelay] = useState(300);
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);

  const { handleSubmit, watch, formState: { errors }, control } = useForm({ mode: 'all' });
  const [otp, setOtp] = useState('');
  const [passwordForm, setPasswordForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { authData } = useSelector(
    ({ auth }: RootReducerState) => ({
      authData: auth.data,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (authData?.accessToken) {
      navigate('/admin/dashboard');
    }
  }, [authData])

  const handleChange = (newValue: any) => {
    setOtp(newValue)
  }

  async function handleSumbitOtp(e: any) {
    e.preventDefault()
    if (!otp || otp?.length !== otpLength) {
      toastMessage('Please enter the otp code.', 'error');
      return false;
    }
    if (!email || !otpType) {
      toastMessage('Otp type and email not found.', 'error');
      return false;
    }

    if (otpType === OtpConstant.forgotPassword || otpType === OtpConstant.register || otpType === OtpConstant.changepassword) {
      setLoading(true);
      Axios.post(`${API_ENDPOINTS.VERIFY_OTP}`, { otpCode: otp, otpFor: email, otpType: OtpConstant[otpType] })
        .then((res: any) => {
          toastMessage('Otp Verified.', 'success')
          if (otpType === OtpConstant.forgotPassword) {
            setPasswordForm(true);
            setLoading(false);
            setOtp(otp)
          } else if (otpType === OtpConstant.register) {
            navigate('/auth/login');
          }
        })
        .catch((err) => {
          setLoading(false);
          const message = JSON.stringify(err?.response?.data?.error) ?? 'Opps something wrong!';
          toastMessage(message, 'error');
        });
    } else {
      toastMessage('Otp type not found.', 'error');
    }
  }



  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  });

  async function onSubmit(data: any) {
    setLoading(true);
    Axios.post(`${API_ENDPOINTS.RESET_PASSWORD}`, { otpCode: otp, otpFor: email, password: data.password })
      .then((res: any) => {
        // const { data, status } = res;
        toastMessage('Password has reset successfully.', 'success');
        setLoading(false);
        navigate('/auth/login');
      })
      .catch((err) => {
        setLoading(false);
        const message = JSON.stringify(err?.response?.data?.error) ?? 'Opps something wrong!';
        toastMessage(message, 'error');
      });
  }

  async function onResendOtp() {
    if (otpType === OtpConstant.forgotPassword || otpType === OtpConstant.register || otpType === OtpConstant.changepassword) {
      setLoading(true);
      Axios.post(`${API_ENDPOINTS.SEND_OTP}`, {
        email: email,
        otpType: OtpConstant[otpType]
      })
        .then((res: any) => {
          setLoading(false);
          setDelay(600)
          setOtp('')
          toastMessage('Otp has send sucessfully.', 'success');
        })
        .catch((err) => {
          setLoading(false);
          const message = JSON.stringify(err?.response?.data?.error) ?? 'Opps something wrong!';
          toastMessage(message, 'error');
        });
    }
    else {
      toastMessage('Otp type and email not found.', 'error');
    }
  }

  return (
    <Container maxWidth="xl" sx={{ background: "#F5F5F5" }}>
      <Helmet>
        <title>OTP | Gharawas - Reset Your One-Time Password</title>
        <meta name="description" content="Enter the OTP received on your registered mobile number to verify your agent account on Gharawas." />
        <meta name="keywords" content="Gharawas, agent OTP, verification, real estate, account verification" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Gharawas" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {loading && <HoverLoader />}
      <ContentStyle>
        <Card sx={{ p: 2 }}>

          <Typography variant="h4" gutterBottom>
            {!passwordForm ? "OTP verification" : "Reset Password"}
          </Typography>
          <Typography variant="body2" sx={{ mb: 5 }}>
            Already have an account? {' '}
            <Link to='/auth/login' style={{ textDecoration: 'none', color: "#6327e4", fontSize: 14 }}>
              Sign Up
            </Link>
          </Typography>
          {!passwordForm ? <>
            <Stack spacing={3}>
              <MuiOtpInput value={otp} gap={0.5} onChange={handleChange} length={otpLength} />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              <Typography variant="body2">
              </Typography>
              <Typography variant="body2" sx={{ cursor: 'pointer' }} onClick={() => (minutes === 0 && seconds === 0) ? onResendOtp() : null}>
                Resend Otp Code  {(minutes > 0 || seconds > 0) && `in  ${minutes}:${seconds} min`}
              </Typography>
            </Stack>
            <LoadingButton disabled={otp.length === otpLength ? false : true} fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }} onClick={handleSumbitOtp}>
              Verify
            </LoadingButton>
          </>
            :
            <Stack spacing={2}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <CustomTextBox
                  label={formatMessage({ id: "password" })}
                  name="password"
                  control={control}
                  error={errors?.password}
                  margin="normal"
                  type="password"
                  rules={{
                    ...validationRule.textbox({ required: true, minLength: 6 }),
                    validate: (value: any) => (watch('confirmation_password') ? (watch('confirmation_password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                  }}
                  autoComplete="off"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                        <Tooltip title={formatMessage({ id: "password-policy-message" })}>
                          <InfoIcon color="primary" />
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />

                <CustomTextBox
                  label={formatMessage({ id: "confirm-password" })}
                  name="confirmation_password"
                  control={control}
                  error={errors?.confirmation_password}
                  margin="normal"
                  type="password"
                  rules={{
                    ...validationRule.textbox({ required: true, minLength: 6 }),
                    validate: (value: any) => (watch('password') ? (watch('password') === value) : true) || formatMessage({ id: "password-confirm-validation-message" })
                  }}
                  autoComplete="off"
                />

                <LoadingButton fullWidth size="large" type="submit" variant="contained" sx={{ my: 2 }} >
                  Change Password
                </LoadingButton>
              </form>
            </Stack>
          }
        </Card>
      </ContentStyle>
    </Container>
  );
}
