import { Navigate, Outlet, useRoutes } from "react-router-dom";
import DashboardLayout from "layouts/dashboard";
import MainLayout from "layouts";
import {
  Property,
  PropertyForm,
  Blog,
  BlogForm,
  Role,
  Dashboard,
  Setting,
  Banner,
  BannerForm,
  User,
  UserForm,
  PropertyTag,
  Amenities,
  EditPropertyForm,
  Category,
  Address,
  Owner,
  Partner,
  Admin,
  AdminForm,
  Account,
  AccountForm,
  PropertyStatus,
  PropertyFace,
  RoadType,
  AreaUnit,
  Advertisement,
  PageForm,
  Page,
  Contact,
  BlogCategory,
  BlogTag,
} from "pages";
import Login from "pages/Login";
import NotFound from "pages/Page404";
import { roleData } from "utils/constants/role";
import PrivateRoute from "./PrivateRoute";
import Register from "pages/Register";
import ForgotPassword from "pages/ForgotPassword";
import VerifyOtp from "pages/VerifyOtp";

export default function Router() {
  return useRoutes([
    {
      path: "/admin",
      element: (
        <PrivateRoute roles={[roleData.superadmin, roleData.admin, roleData.blog,roleData.agent]}>
          <DashboardLayout />
        </PrivateRoute>
      ),
      children: [
        {
          path: "dashboard",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin, roleData.blog,roleData.agent]}>
              <Dashboard />
            </PrivateRoute>
          ),
        },
        {
          path: "blogs",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin, roleData.blog,roleData.agent]}>
              <Blog />
            </PrivateRoute>
          ),
        },
        {
          path: "blogs/:formType",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin, roleData.blog,roleData.agent]}>
              <BlogForm />
            </PrivateRoute>
          ),
        },
        {
          path: "blogs/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin, roleData.blog,roleData.agent]}>
              <BlogForm />
            </PrivateRoute>
          ),
        },

        {
          path: "pages",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Page />
            </PrivateRoute>
          ),
        },
        {
          path: "pages/:formType",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <PageForm />
            </PrivateRoute>
          ),
        },
        {
          path: "pages/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <PageForm />
            </PrivateRoute>
          ),
        },
        {
          path: "banners",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Banner />
            </PrivateRoute>
          ),
        },
        {
          path: "banners/:formType",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <BannerForm />
            </PrivateRoute>
          ),
        },
        {
          path: "banners/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <BannerForm />
            </PrivateRoute>
          ),
        },
        {
          path: "properties",
          element: (
            <PrivateRoute roles={[roleData.admin,roleData.agent]}>
              <Property />
            </PrivateRoute>
          ),
        },
        {
          path: "properties/:formType",
          element: (
            <PrivateRoute roles={[roleData.admin,roleData.agent]}>
              <PropertyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "properties/:formType/:tab/:uuid",
          element: (
            <PrivateRoute roles={[roleData.admin,roleData.agent]}>
              <EditPropertyForm />
            </PrivateRoute>
          ),
        },
        {
          path: "customers",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <User />
            </PrivateRoute>
          ),
        },
        {
          path: "customers/:formType",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <UserForm />
            </PrivateRoute>
          ),
        },
        {
          path: "customers/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <UserForm />
            </PrivateRoute>
          ),
        },
        {
          path: "account-users",
          element: (
            <PrivateRoute roles={[roleData.superadmin,roleData.admin]}>
              <Admin />
            </PrivateRoute>
          ),
        },
        {
          path: "account-users/:formType",
          element: (
            <PrivateRoute roles={[roleData.superadmin,roleData.admin]}>
              <AdminForm />
            </PrivateRoute>
          ),
        },
        {
          path: "account-users/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.superadmin,roleData.admin]}>
              <AdminForm />
            </PrivateRoute>
          ),
        },

        {
          path: "accounts",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Account />
            </PrivateRoute>
          ),
        },
        {
          path: "accounts/:formType",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <AccountForm />
            </PrivateRoute>
          ),
        },
        {
          path: "accounts/:formType/:uuid",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <AccountForm />
            </PrivateRoute>
          ),
        },
        {
          path: "roles",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Role />
            </PrivateRoute>
          ),
        },
        {
          path: "owners",
          element: (
            <PrivateRoute roles={[roleData.admin,roleData.agent]}>
              <Owner />
            </PrivateRoute>
          ),
        },
        {
          path: "profile",
          element: (
            <PrivateRoute roles={[roleData.admin,roleData.superadmin]}>
              <Setting />
            </PrivateRoute>
          ),
        },
        {
          path: "inquiry",
          element: (
            <PrivateRoute roles={[roleData.superadmin, roleData.admin,roleData.agent]}>
              <Contact />
            </PrivateRoute>
          ),
        },
        // {
        //   path: "spoken-language",
        //   element: (
        //     <PrivateRoute roles={[roleData.admin]}>
        //       <SpokenLanguage />
        //     </PrivateRoute>
        //   ),
        // },
        {
          path: "blog-categories",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <BlogCategory />
            </PrivateRoute>
          ),
        },
        {
          path: "blog-tags",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <BlogTag />
            </PrivateRoute>
          ),
        },
        {
          path: "property-tags",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <PropertyTag />
            </PrivateRoute>
          ),
        },
        {
          path: "amenities",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Amenities />
            </PrivateRoute>
          ),
        },
        {
          path: "property-status",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <PropertyStatus />
            </PrivateRoute>
          ),
        },
        {
          path: "property-face",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <PropertyFace />
            </PrivateRoute>
          ),
        },
        {
          path: "road-type",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <RoadType />
            </PrivateRoute>
          ),
        },
        {
          path: "area-units",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <AreaUnit />
            </PrivateRoute>
          ),
        },
        {
          path: "category",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Category />
            </PrivateRoute>
          ),
        },
        {
          path: "partner",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Partner />
            </PrivateRoute>
          ),
        },
        {
          path: "advertisement",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Advertisement />
            </PrivateRoute>
          ),
        },
        {
          path: "address",
          element: (
            <PrivateRoute roles={[roleData.superadmin]}>
              <Address />
            </PrivateRoute>
          ),
        },
      ],

    },
    {
      path: "/auth",
      element: <MainLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "verify", element: <VerifyOtp /> },
      ]
    },
    { path: "/", element: <Navigate to="/auth/login" /> },
    { path: "404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
