import { shallowEqual, useSelector } from 'react-redux';
import { roleData } from 'utils/constants/role';
import { findCommonItems } from 'utils/global';
import { RootReducerState } from 'utils/types';

export function useCheckRole() {
    const { authData } = useSelector(
        ({ auth }: RootReducerState) => ({
            authData: auth.data,
            loading: auth.loading,
        }),
        shallowEqual
    );

    const isSuperAdmin = findCommonItems([roleData.superadmin],authData.roles);
    const isAdmin = findCommonItems([roleData.admin],authData.roles);
    const isAgent = findCommonItems([roleData.agent],authData.roles);
    const isBlog = findCommonItems([roleData.blog],authData.roles);

    return {
        isAgent,
        isAdmin,
        isSuperAdmin,
        isBlog
    }
}