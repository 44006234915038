import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useCreateLookupOptions } from 'hooks';
import FormLayout from 'layouts/FormLayout';
import { masterLookupServices } from 'utils/constants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootReducerState } from 'utils/types';
import { getMasterLookupData } from 'redux/actions';
import GeneralForm from './GeneralForm';

const CreateForm: React.FC = () => {
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();
    const { propertyLookUp } = useSelector(
        ({ masterLookupReducer }: RootReducerState) => {
            return ({
                propertyLookUp: masterLookupReducer[masterLookupServices.getPropertyLookup].data
            })
        },
        shallowEqual
    );

    const selectOptions = useCreateLookupOptions(propertyLookUp);

    useEffect(() => {
        dispatch(getMasterLookupData(masterLookupServices.getPropertyLookup));
    }, []);
    
    return (
        <FormLayout formType={"create"} title={formatMessage({ id: "property" })} showFooter={false}>
            <GeneralForm
                uuid={null}
                formType="create"
                defaultData={null}
                selectOptions={selectOptions}
            />
        </FormLayout>
    )
}

export default CreateForm;